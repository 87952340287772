import { preparePaymentPath } from "./helpers";

export const ENV_VARS = {
  api_url: process.env.REACT_APP_DEV_API_URL as string,
  pos_url: process.env.REACT_APP_DEV_POS_API_URL as string,
  search_url: process.env.REACT_APP_DEV_SEARCH_API_URL as string,
  payment_url: process.env.REACT_APP_DEV_CABIN_SELECT_PAYMENT_DOMAIN as string,
  api_username: process.env.REACT_APP_DEV_API_USERNAME as string,
  api_password: process.env.REACT_APP_DEV_API_password as string,
  payment_api_path: preparePaymentPath(
    process.env.REACT_APP_PAYMENT_API_PATH as string,
    process.env.REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE as string,
  ),
  date_format: process.env.REACT_APP_CABIN_SELECT_DATE_FORMAT as string,
  app_language: process.env.REACT_APP_CABIN_SELECT_APP_LANGUAGE as string,
  api_language: process.env.REACT_APP_CABIN_SELECT_API_LANGUAGE as string,
  breadcrumbs_text: process.env.REACT_APP_BREADCRUMBS_TEXT as string,
  search_item_image_source: process.env.REACT_APP_SEARCH_ITEM_IMAGE_SOURCE as
    | "ship"
    | "cruise",
  authorize_api_login_id: process.env
    .REACT_APP_CABIN_SELECT_AUTHORIZE_API_LOGIN_ID as string,
  authorize_client_key: process.env
    .REACT_APP_CABIN_SELECT_AUTHORIZE_CLIENT_KEY as string,
  privacy_policy: process.env.REACT_APP_PRIVACY_POLICY as string,
  payment_checkbox_1: process.env.REACT_APP_PAYMENT_CHECKBOX_1 as string,
  payment_checkbox_2: process.env.REACT_APP_PAYMENT_CHECKBOX_2 as string,
  payment_checkbox_3: process.env.REACT_APP_PAYMENT_CHECKBOX_3 as string,
  payment_checkbox_4: process.env.REACT_APP_PAYMENT_CHECKBOX_4 as string,
  payment_checkbox_5: process.env.REACT_APP_PAYMENT_CHECKBOX_5 as string,
  subtract_gft: process.env.REACT_APP_SUBTRACT_GFT === "true",
  strip_no_stop_itinerary_items:
    process.env.REACT_APP_STRIP_NO_STOP_ITINERARY_ITEMS === "true",
  show_pricing_breakdown:
    process.env.REACT_APP_SHOW_PRICING_BREAKDOWN === "true",
  check_email_uniqueness:
    process.env.REACT_APP_CHECK_EMAIL_UNIQUENESS === "true",
  authorize_is_test:
    process.env.REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_TEST === "true",
  authorize_is_mine:
    process.env.REACT_APP_CABIN_SELECT_AUTHTORIZE_IS_MINE === "true",
  primary_color: process.env.REACT_APP_PRIMARY_COLOR as string,
  secondary_color: process.env.REACT_APP_SECONDARY_COLOR as string,
  text_primary_color: process.env.REACT_APP_TEXT_PRIMARY_COLOR as string,
  text_secondary_color: process.env.REACT_APP_TEXT_SECONDARY_COLOR as string,
  subtext_color: process.env.REACT_APP_SUBTEXT_COLOR as string,
  link_color: process.env.REACT_APP_LINK_COLOR as string,
  input_border_color: process.env.REACT_APP_INPUT_BORDER_COLOR as string,
  input_background_color: process.env
    .REACT_APP_INPUT_BACKGROUND_COLOR as string,
  input_placeholder_color: process.env
    .REACT_APP_INPUT_PLACEHOLDER_COLOR as string,
  input_focus_border_color: process.env
    .REACT_APP_INPUT_FOCUS_BORDER_COLOR as string,
  input_text_color: process.env.REACT_APP_INPUT_TEXT_COLOR as string,
  error_color: process.env.REACT_APP_ERROR_COLOR as string,
  menu_background_color: process.env.REACT_APP_MENU_BACKGROUND_COLOR as string,
  menu_text_color: process.env.REACT_APP_MENU_TEXT_COLOR as string,
  element_background_primary: process.env
    .REACT_APP_ELEMENT_BACKGROUND_PRIMARY as string,
  element_background_secondary: process.env
    .REACT_APP_ELEMENT_BACKGROUND_SECONDARY as string,
  element_background_dark: process.env
    .REACT_APP_ELEMENT_BACKGROUND_DARK as string,
  background_color: process.env.REACT_APP_BACKGROUND_COLOR as string,
  section_background_color: process.env
    .REACT_APP_SECTION_BACKGROUND_COLOR as string,
  shadow_color: process.env.REACT_APP_SHADOW_COLOR as string,
  delimiter_line_color: process.env.REACT_APP_DELIMITTER_LINE_COLOR as string,
  svg_icons_primary: process.env.REACT_APP_SVG_ICONS_PRIMARY as string,
  svg_icons_secondary: process.env.REACT_APP_SVG_ICONS_SECONDARY as string,
  pagination_type: process.env.REACT_APP_PAGINATION_TYPE as string,
  pagination_count: isNaN(Number(process.env.REACT_APP_PAGINATION_COUNT))
    ? 10
    : Number(process.env.REACT_APP_PAGINATION_COUNT),
  search_button_background: process.env
    .REACT_APP_SEARCH_BUTTON_BACKGROUND as string,
};
