// Utility for generating unique IDs
import { nanoid } from "@reduxjs/toolkit";
// React hook for managing state
import { useState } from "react";

// Component for displaying SVG icons
import SvgIcon from "../SvgIcon";

// Component-specific styles
import styles from "./index.module.scss";
// Utility for conditional class names
import classNames from "classnames";

// Interface for component props
interface IFilePickerProps {
  valueKey: string;
  errorMessage?: string;
  onChange: ({
    value,
    valueKey,
  }: {
    value: FileList | null;
    valueKey: string;
  }) => void;
}

// FilePicker component for file upload
function FilePicker({ valueKey, errorMessage, onChange }: IFilePickerProps) {
  // State for the selected file and drag-over flag
  const [file, setFile] = useState<File | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  // Unique ID for the input element
  const id = nanoid();

  // Handler for input change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.files;

    // Calling the onChange callback with the new value
    onChange({ value, valueKey });

    // Updating the state with the selected file
    if (value?.length && value.length > 0) {
      setFile(value[0]);
    }
  };

  // Handler for removing the selected file
  const handleRemoveFile = () => {
    setFile(null);
    onChange({ value: null, valueKey });
  };

  // Handler for drag over event on the label
  const handleLabelDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  // Handler for drag leave event on the label
  const handleLabelDragLeave = () => {
    setIsDragOver(false);
  };

  // Handler for drop event on the label
  const handleLabelDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    setIsDragOver(false);

    const value = e.dataTransfer.files;

    // Calling the onChange callback with the new value
    onChange({ value, valueKey });

    // Updating the state with the dropped file
    if (value?.length && value.length > 0) {
      setFile(value[0]);
    }
  };

  // Returning the file picker UI
  return (
    <div className={styles.container}>
      <div className={styles.pickerContainer}>
        <label
          htmlFor={id}
          className={classNames(styles.picker, {
            [styles.picker_dragOver]: isDragOver,
          })}
          onDragOver={handleLabelDragOver}
          onDragLeave={handleLabelDragLeave}
          onDrop={handleLabelDrop}>
          <SvgIcon type="upload" className={styles.uploadIcon} />

          <div className={styles.text}>
            <p className={styles.uploadButton}>
              <span>Click to upload</span> or drag and drop your file
            </p>

            <p className={styles.size}>Maximum file size: 3mb </p>
          </div>
        </label>

        <div className={styles.fileContainer}>
          <div className={styles.file}>
            <span>{file?.name ?? "File not picked"}</span>
          </div>

          <div className={styles.removeButtonContainer}>
            <span onClick={handleRemoveFile}>Remove</span>
          </div>
        </div>
      </div>

      <span className={styles.error}>{errorMessage}</span>

      <input
        id={id}
        type="file"
        accept=".pdf, .doc, .jpeg, .jpg"
        multiple={false}
        hidden
        onChange={handleChange}
      />
    </div>
  );
}

// Default props for the FilePicker component
FilePicker.defaultProps = {
  errorMessage: "",
};

// Exporting the component
export default FilePicker;
