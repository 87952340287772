// Hook for internationalization to support multiple languages in the UI.
import { useTranslation } from "react-i18next";

// Reusable UI components for form inputs and date picking.
import Input from "../../shared/Input";
import DatePicker, { DatePickerHandler } from "../../shared/DatePicker";
// Core React hooks for managing component state, side effects, and references.
import { useEffect, useMemo, useRef, useState } from "react";
// Component to display the main content of the page.
import Content from "./molecules/Content";

// Styles specific to this component, managed via CSS modules for scope.
import styles from "./index.module.scss";
// Hook for programmatic navigation.
import { useNavigate } from "react-router-dom";
// Constants for application routes.
import { SEARCH } from "../../../utils/constants/routes";
// Custom hook for fetching the list of reservations.
import { useLazyInitReservationListQuery } from "../../../store/services/ReservationService";
// Hook for accessing Redux state in a typed manner.
import { useTypedSelector } from "../../../store/store";
// Component to indicate loading state.
import LoadingContainer from "../../containers/LoadingContainer";
// Type definition for the reservation list state.
import { ReservationListInterface } from "../../../store/slices/reservationSlice";
// Library for date manipulation.
import dayjs from "dayjs";

// Types for managing form inputs and sorting.
interface InputInterface {
  value: string[];
  errorMessage?: string;
}

interface SortOnInterface {
  type: string;
  first: boolean;
}

interface SearchInputsInterface {
  [key: string]: InputInterface;
  bookingName: InputInterface;
  embarkDate: InputInterface;
  yachtName: InputInterface;
}

// The functional component for displaying current bookings.
function CurrentBookings() {
  // Internationalization and navigation hooks.
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Reference to the DatePicker component for managing its state.
  const datePickerRef = useRef<DatePickerHandler>(null);

  const bookingList = useTypedSelector(
    (state) => state.reservation.reservationList,
  );

  const [bookingListState, setBookingListState] = useState<
    ReservationListInterface[]
  >([]);

  const session = useTypedSelector((state) => state.session);

  const [searchInputs, setSearchInputs] = useState<SearchInputsInterface>({
    bookingName: { value: [""] },
    embarkDate: {
      value: [],
      errorMessage: "",
    },
    yachtName: { value: [""] },
  });

  // Function to check if a date falls within a specified range.
  const checkDateFilter = (
    startDate: string,
    endDate: string,
    checkDate: string,
  ) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const check = new Date(checkDate);

    return check >= start && check <= end;
  };

  // State and useEffect hook for filtering the booking list based on search criteria.
  const [filterBookingList, setFilterBookingList] = useState<
    ReservationListInterface[]
  >([]);

  useEffect(() => {
    const filterTotal = bookingList.filter((item) => {
      const checkBookingName = searchInputs.bookingName.value[0]
        ? item.lead_given_name
            .toLowerCase()
            .includes(searchInputs.bookingName.value[0].toLowerCase())
        : false;
      const checkYachName = searchInputs.yachtName.value[0]
        ? item.cruise_name
            .toLowerCase()
            .includes(searchInputs.yachtName.value[0].toLowerCase())
        : false;

      const checkDate = checkDateFilter(
        searchInputs.embarkDate.value[0],
        searchInputs.embarkDate.value[1],
        item.embarkation_date,
      );

      return checkBookingName || checkYachName || checkDate;
    });

    setFilterBookingList(filterTotal);
  }, [searchInputs]);

  // useMemo hook to determine if any filters are actively being used.
  const isUsingFilter = useMemo(() => {
    for (const key in searchInputs) {
      const value = searchInputs[key].value;

      if (value.length !== 0 && value[0] !== "") {
        return true;
      }
    }

    return false;
  }, [searchInputs]);

  // State for managing sorting criteria.
  const [sortOn, setSortOn] = useState({ type: "", first: false });

  // Function to sort the booking list based on a given criteria.
  const sortByCriteria = (
    data: ReservationListInterface[],
    type: string,
    sortOn: SortOnInterface,
    setSortOn: (args: SortOnInterface) => void,
    sortFunction: (
      a: ReservationListInterface,
      b: ReservationListInterface,
    ) => number,
  ) => {
    if (sortOn.type === type) {
      const sortedData = data.sort(sortFunction);

      setSortOn({ type, first: !sortOn.first });

      return sortedData;
    } else {
      const sortedData = data.sort(sortFunction);

      setSortOn({ type, first: false });

      return sortedData;
    }
  };

  // Handler for sorting the booking list.
  const sortBookingList = (type: string) => {
    switch (type) {
      case "passenger_name":
        setFilterBookingList((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) =>
              !sortOn.first
                ? a.lead_given_name.localeCompare(b.lead_given_name)
                : b.lead_given_name.localeCompare(a.lead_given_name),
          ),
        );
        setBookingListState((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) =>
              !sortOn.first
                ? a.lead_given_name.localeCompare(b.lead_given_name)
                : b.lead_given_name.localeCompare(a.lead_given_name),
          ),
        );
        break;
      case "embarkation_date":
        setFilterBookingList((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) => {
              const dateA = dayjs(a.embarkation_date);
              const dateB = dayjs(b.embarkation_date);

              return !sortOn.first ? dateA.diff(dateB) : dateB.diff(dateA);
            },
          ),
        );
        setBookingListState((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) => {
              const dateA = dayjs(a.embarkation_date);
              const dateB = dayjs(b.embarkation_date);

              return !sortOn.first ? dateA.diff(dateB) : dateB.diff(dateA);
            },
          ),
        );
        break;
    }
  };

  // Handler for input changes in the form.
  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string | string[];
    valueKey?: string;
  }) => {
    if (valueKey) {
      setSearchInputs((prev: SearchInputsInterface) => {
        return {
          ...prev,
          [valueKey]: {
            errorMessage: "",
            value: Array.isArray(value) ? value : [value],
          },
        };
      });
    }
  };

  // Handler to reset the filters back to their initial state.
  const resetFiltersHandler = () => {
    setSearchInputs({
      bookingName: { value: [""] },
      embarkDate: {
        value: [],
        errorMessage: "",
      },
      yachtName: { value: [""] },
      expeditionType: { value: [] },
    });

    datePickerRef.current?.handleResetDate();
  };

  // Fetching and loading state management for reservation list.
  const [getReservation] = useLazyInitReservationListQuery();
  const [isReservationLoading, setReservationLoading] = useState(true);

  useEffect(() => {
    getReservation({
      channel: "CS",
      channel_partner: session.agency,
      agency: session.agent,
    })
      .then((res) => {
        if (!res.data) return;
        setBookingListState(res.data);
      })
      .finally(() => {
        setReservationLoading(false);
      });
  }, []);

  // Rendering the component's UI.
  return (
    <LoadingContainer isLoading={isReservationLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.filtersHeader}>
            <h1>{t("CURRENT BOOKINGS")}</h1>
            <button
              className={styles.startBooking}
              onClick={() => navigate(SEARCH)}>
              {t("START A BOOKING")}
            </button>
          </div>
          <div className={styles.filtersHeader}>
            <span>{t("Click on a booking to find out more information.")}</span>
          </div>
          <div className={styles.filtersHeader}>
            <span>{t("FILTER BOOKINGS BY")}</span>
            <button
              className={styles.resetFilters}
              onClick={resetFiltersHandler}>
              {t("RESET FILTERS")}
            </button>
          </div>
          <form className={styles.form}>
            <Input
              value={searchInputs.bookingName.value[0]}
              valueKey="bookingName"
              placeholder={t("by name")}
              errorMessage={searchInputs.bookingName.errorMessage}
              onChange={handleInputChange}
              className={styles.input}
            />

            <DatePicker
              valueKey="embarkDate"
              placeholder={t("embark date")}
              returnFormat="YYYY-MM"
              defaultDate={searchInputs.embarkDate.value}
              errorMessage={searchInputs.embarkDate.errorMessage}
              onChange={handleInputChange}
              ref={datePickerRef}
              className={styles.inputDate}
              mode="range"
              minDate={dayjs(dayjs().subtract(1, "month"))}
            />

            <Input
              value={searchInputs.yachtName.value[0]}
              valueKey="yachtName"
              placeholder={t("yacht name")}
              errorMessage={searchInputs.yachtName.errorMessage}
              onChange={handleInputChange}
              className={styles.input}
            />
          </form>
          <Content
            bookingList={isUsingFilter ? filterBookingList : bookingListState}
            sortBookingList={sortBookingList}
          />
        </div>
      </div>
    </LoadingContainer>
  );
}

// Export the component for use elsewhere in the application.
export default CurrentBookings;
