import { CURRENT_BOOKINGS, PREVIOUS_BOOKINGS, SEARCH } from "./routes";
import i18n from "../i18n";

const { t } = i18n;

const TILES = {
  start: {
    title: t("start a booking"),
    description: t(
      "Start a search for an expedition and and complete a booking.",
    ),
    color: "teal",
    path: SEARCH,
  },
  current: {
    title: t("current bookings"),
    description: t(
      "Here you can refer to your confirmed bookings before they embark on their expedition.",
    ),
    color: "lilac",
    path: CURRENT_BOOKINGS,
  },
  previous: {
    title: t("previous bookings"),
    description: i18n.t(
      "Here you can refer to your confirmed bookings before they embark on their expedition.",
    ),
    color: "lilac",
    path: PREVIOUS_BOOKINGS,
  },
};

const AGENT_HOME = { TILES };

export default AGENT_HOME;
