// Importing React hooks for side effects and memoization.
import { useEffect, useMemo } from "react";
// Importing Day.js for date manipulation.
import dayjs from "dayjs";

// Action creators for updating session state in the Redux store.
import {
  updateAgency,
  updateAgent,
  updateAgentName,
  updateSessionKey,
  updateToken,
} from "../../store/slices/sessionSlice";

// Thunk actions for initializing agency details.
import { getAgencyDetails } from "../../store/actions/agencyActions";

// Thunk actions for initializing or destroying the session.
import {
  destroySession,
  initApiToken,
  initPosSession,
} from "../../store/actions/sessionActions";

// Hook for accessing the Redux dispatch function and state selector.
import { useTypedDispatch, useTypedSelector } from "../../store/store";
// Custom hook for managing cookies.
import useCookies from "../../hooks/useCookies";
// Constants for toast notifications.
import TOAST from "../../utils/constants/toast";
// Action creator for displaying toast notifications.
import { showToast } from "../../store/slices/toastSlice";
// Component that displays a loading indicator or its children based on a condition.
import LoadingContainer from "../containers/LoadingContainer";

// Helper function to determine if the authentication token is still valid.
const getIsTokenValid =
  (token: string | null, tokenExp: string | null) => () => {
    // Return false immediately if token or expiration date is missing.
    if (!token || !tokenExp) {
      return false;
    }

    // Compare the current time with the token's expiration time.
    const now = dayjs();
    const exp = dayjs(tokenExp);

    // If the expiration time is before the current time, token is invalid.
    if (exp.isBefore(now)) {
      return false;
    }

    return true;
  };

// Component that wraps children components and manages session state initialization.
function SessionGuard({ children }: React.PropsWithChildren) {
  // Hook to dispatch actions to the Redux store.
  const dispatch = useTypedDispatch();
  // Hook to get and set cookies.
  const { getCookie, setCookie } = useCookies();

  // Retrieve session-related state from the Redux store.
  const { isInitialized, name, agent, agency, sessionKey, token } =
    useTypedSelector((state) => state.session);

  // Get session-related data from cookies.
  const cookieToken = getCookie("token");
  const tokenExp = getCookie("token_exp");
  const cookieSessionKey = getCookie("sessionKey");
  const cookieAgentName = getCookie("agentName");
  const cookieAgency = getCookie("agency");
  const cookieAgent = getCookie("agent");

  // Determine if the current token is valid using memoization to avoid unnecessary recalculations.
  const isTokenValid = useMemo(getIsTokenValid(cookieToken, tokenExp), []);

  // Initializes the token state in Redux store if the cookie token is valid, otherwise requests a new token.
  const initToken = () => {
    if (isTokenValid) {
      dispatch(updateToken(cookieToken));
    } else {
      dispatch(initApiToken());
    }
  };

  // Initializes agent session-related data in the Redux store and updates cookies.
  const initAgentSession = () => {
    dispatch(updateAgentName(cookieAgentName));
    dispatch(updateSessionKey(cookieSessionKey));
    dispatch(updateAgency(cookieAgency));
    dispatch(updateAgent(cookieAgent));

    // Extend the session key expiration by setting a new cookie.
    const sessionKeyExp = dayjs().add(3600, "seconds").format();

    setCookie("sessionKey_exp", sessionKeyExp);
  };

  // Effect hook to initialize token state when component mounts.
  useEffect(initToken, []);

  // Effect hook to validate session key expiration and initialize session data.
  useEffect(() => {
    const sessionKeyExp = getCookie("sessionKey_exp");

    // If session key expiration is defined, check its validity.
    if (sessionKeyExp) {
      const isSessionKeyValid = dayjs().isBefore(dayjs(sessionKeyExp));

      // If session key is not valid, show error toast and destroy session.
      if (!isSessionKeyValid) {
        setTimeout(() => {
          dispatch(
            showToast({
              type: TOAST.ERROR_TYPE,
              message: "Token request error",
              duration: TOAST.DEFAULT_DURATION,
            }),
          );
        }, 2000);

        dispatch(destroySession());
      } else {
        // If session key is valid, initialize agent session data.
        initAgentSession();
      }
    }
  }, []);

  // Effect hook to initialize POS session if agent and agency data are present but not in cookies.
  useEffect(() => {
    if (agent && agency && !cookieAgent && !cookieAgency) {
      dispatch(initPosSession());
    }
  }, [agent, agency]);

  // Effect hook to initialize agency details if agency data is present.
  useEffect(() => {
    if (agency) {
      dispatch(getAgencyDetails(agency));
    }
  }, [agency]);

  // Effect hook to update token cookie when token updates and is valid.
  useEffect(() => {
    if (token && !isTokenValid) {
      setCookie("token", token);
      setCookie("token_exp", dayjs().add(3600, "seconds").format());
    }
  }, [token]);

  // Effect hook to update cookies when session data updates.
  useEffect(() => {
    if (name && !cookieAgentName) {
      setCookie("agentName", name);
    }

    if (sessionKey && !cookieSessionKey) {
      setCookie("sessionKey", sessionKey);
    }

    if (agency && !cookieAgency) {
      setCookie("agency", agency);
    }

    if (agent && !cookieAgent) {
      setCookie("agent", agent);
    }
  }, [sessionKey, name, agency, agent]);

  // Render children wrapped in a LoadingContainer, which shows a loading indicator until the session is initialized.
  return (
    <LoadingContainer isLoading={!isInitialized}>{children}</LoadingContainer>
  );
}

// Export SessionGuard for use as a wrapper around components that require session initialization.
export default SessionGuard;
