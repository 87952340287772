// Importing necessary hooks and components from React and react-i18next libraries, as well as a custom hook useTypedSelector from our store.
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Navigation from "./Navigation";
import { useTypedSelector } from "./store/store";

// The main App component.
function App() {
  // Getting the i18n object from the useTranslation() hook.
  const { i18n } = useTranslation();

  // Getting the state variables from our store using the custom hook useTypedSelector().
  const {
    // Variables defining the themes and styles of the application.
    app_language,
    primary_color,
    secondary_color,
    text_primary_color,
    text_secondary_color,
    input_border_color,
    input_background_color,
    input_placeholder_color,
    input_text_color,
    input_focus_border_color,
    error_color,
    menu_background_color,
    menu_text_color,
    element_background_primary,
    element_background_secondary,
    element_background_dark,
    background_color,
    link_color,
    section_background_color,
    shadow_color,
    subtext_color,
    svg_icons_primary,
    svg_icons_secondary,
    search_button_background,
  } = useTypedSelector((state) => state.environment);

  // Effect hook to dynamically set CSS variables based on theme variables.
  useEffect(() => {
    // Creating an object with CSS variable names as keys and theme variable values as values.
    const styles = {
      "--primary-color": primary_color || "#007481",
      "--secondary-color": secondary_color || "#D5D4DD",
      "--text-primary-color": text_primary_color || "#3C3A59",
      "--text-secondary-color": text_secondary_color || "#FFFFFF",
      "--input-border-color": input_border_color || "#A7A7A7",
      "--input-background-color": input_background_color || "#FFFFFF",
      "--input-placeholder-color": input_placeholder_color || "#818181",
      "--input-text-color": input_text_color || "#545454",
      "--input-focus-border-color": input_focus_border_color || "#00407A",
      "--error-color": error_color || "#FA6B63",
      "--menu-background-color": menu_background_color || "#007481",
      "--menu-text-color": menu_text_color || "#FFFFFF",
      "--background-color": background_color || "#F5F5F5",
      "--link-color": link_color || "#007481",
      "--section-background-color": section_background_color || "#FFFFFF",
      "--shadow-color": shadow_color || "rgba(0, 0, 0, 0.05)",
      "--subtext-color": subtext_color || "#545454",
      "--element-background-primary": element_background_primary || "#FFFFFF",
      "--element-background-secondary":
        element_background_secondary || "#D5D4DD",
      "--element-background-dark": element_background_dark || "#3C3A59",
      "--svg-icons-primary": svg_icons_primary || "#3C3A59",
      "--svg-icons-secondary": svg_icons_secondary || "#3C3A59",
      "--search-button-background": search_button_background || "black",
    };

    // Applying styles to the root HTML element.
    Object.entries(styles).forEach(([property, value]) => {
      document.documentElement.style.setProperty(property, value);
    });
  }, [
    // Dependency array includes all theme variables to trigger the effect when they change.
    primary_color,
    secondary_color,
    text_primary_color,
    text_secondary_color,
    input_border_color,
    input_background_color,
    input_placeholder_color,
    input_text_color,
    input_focus_border_color,
    error_color,
    menu_background_color,
    menu_text_color,
    element_background_primary,
    element_background_secondary,
    element_background_dark,
    background_color,
    link_color,
    section_background_color,
    shadow_color,
    subtext_color,
    svg_icons_primary,
    search_button_background,
  ]);

  // Effect hook to handle language change and save it to local storage.
  useEffect(() => {
    i18n.changeLanguage(app_language);
    localStorage.setItem("APP_LANG", app_language);
  }, [app_language]);

  // Rendering the Navigation component.
  return <Navigation />;
}

export default App;
