/**
 * Generates a list of days for a given year and month.
 * @param {string} yearStr - The string representation of the year.
 * @param {string} monthStr - The string representation of the month (case-insensitive).
 * @returns {string[]} An array containing the days of the specified month.
 */
function getDaysList(yearStr: string, monthStr: string) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthIndex = months.findIndex(
    (month) => month.toLowerCase() === monthStr.toLowerCase(),
  );

  const year = parseInt(yearStr, 10);
  const month = monthIndex !== -1 ? monthIndex : new Date().getMonth();

  const currentYear = isNaN(year)
    ? new Date().getFullYear()
    : parseInt(yearStr, 10);

  const daysList = [];
  const daysInMonth = new Date(currentYear, month + 1, 0).getDate();

  for (let i = 1; i <= daysInMonth; i++) {
    daysList.push(String(i).padStart(2, "0"));
  }

  return daysList;
}

export default getDaysList;
