import { useEffect, useMemo, useState } from "react"; // Importing necessary hooks from React
import { useTranslation } from "react-i18next"; // Importing the useTranslation hook for language translation
import dayjs from "dayjs"; // Importing dayjs for date formatting
import { nanoid } from "@reduxjs/toolkit"; // Importing nanoid for generating unique IDs

import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Importing custom hooks from the Redux store
import { useLazyRetrieveReservationQuery } from "../../../store/services/ReservationService"; // Importing the useLazyRetrieveReservationQuery hook from the ReservationService
import { showToast } from "../../../store/slices/toastSlice"; // Importing the showToast action creator from the toastSlice
import TOAST from "../../../utils/constants/toast"; // Importing constants for toast messages
import { currencyToFormat } from "../../../utils/helpers/currency"; // Importing helper function for formatting currency
import { Room } from "../../../store/slices/roomsSlice"; // Importing the Room interface from the roomsSlice

import LoadingContainer from "../../containers/LoadingContainer"; // Importing the LoadingContainer component

import styles from "./index.module.scss"; // Importing styles for the PaymentConfirmation component

// Functional component for displaying payment confirmation details
function PaymentConfirmation() {
  const { t } = useTranslation(); // Accessing the translation function from useTranslation
  const dispatch = useTypedDispatch(); // Accessing the dispatch function from useTypedDispatch
  const [retrieveReservation] = useLazyRetrieveReservationQuery(); // Accessing the retrieveReservation function from useLazyRetrieveReservationQuery

  const { rooms } = useTypedSelector((state) => state.rooms); // Accessing rooms data from the Redux store
  const { cruise } = useTypedSelector((state) => state.search); // Accessing cruise data from the Redux store
  const { reservation } = useTypedSelector((state) => state.reservation); // Accessing reservation data from the Redux store

  // Accessing environment data from the Redux store
  const { agency } = useTypedSelector((state) => state.session);
  const { date_format } = useTypedSelector((state) => state.environment);

  // State variable to track loading status
  const [isLoading, setIsLoading] = useState(true);

  // Memoized array of grades from rooms
  const grades = useMemo(() => {
    return Object.values(rooms ?? {}).map((room: Room) => room.grade);
  }, [rooms]);

  // Memoized sailing information from reservation
  const sailing = useMemo(() => reservation?.sailings?.[0], [reservation]);

  // Effect to initialize reservation data
  useEffect(() => {
    const initReservation = async () => {
      try {
        // Retrieve reservation details
        const { isError } = await retrieveReservation({
          pnr: rooms?.[1]?.pnr ?? "3UKH1R",
          agency,
        });

        // Show toast message if retrieval fails
        if (isError) {
          dispatch(
            showToast({
              type: TOAST.ERROR_TYPE,
              message: t("Retrieving the reservation failed"),
              duration: TOAST.DEFAULT_DURATION,
            }),
          );
        }
      } catch (error) {
        // Show toast message and log error if retrieval fails
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: t("Retrieving the reservation failed"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );

        console.error("initReservation error: ", error);
      } finally {
        // Set loading status to false after retrieval attempt
        setIsLoading(false);
      }
    };

    // Initialize reservation data
    initReservation();

    // Scroll to top of page on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    // Render the LoadingContainer with loading status
    <LoadingContainer isLoading={isLoading}>
      {/* Main container for payment confirmation */}
      <div className={styles.container}>
        <div className={styles.content}>
          {/* Top section containing main reservation information */}
          <div className={styles.topSection}>
            {/* Render main image of the cruise ship */}
            <img
              src={cruise?.ship?.images?.[0] ?? ""}
              className={styles.mainImage}
            />

            {/* Container for reservation information */}
            <div className={styles.reservationInfo}>
              {/* Reservation item containing PNR, cruise name, ship details, and embarkation information */}
              <div className={styles.reservationItem}>
                <p className={styles.pnr}>{reservation?.pnr ?? ""}</p>

                <p className={styles.cruiseName}>
                  {reservation?.sailings?.[0]?.cruise_name ?? ""}
                </p>

                <p className={styles.ship}>
                  {reservation?.sailings?.[0]?.ship_name ?? ""}{" "}
                  {`(${reservation?.sailings?.[0]?.ship_code ?? ""})`}
                </p>

                <p className={styles.embarking}>
                  {`${t("Embarking")} `}
                  {dayjs(
                    reservation?.sailings?.[0]?.embarkation_date ?? "",
                  ).format(date_format)}{" "}
                  {reservation?.sailings?.[0]?.cruise_duration ?? ""}
                  {` ${t("nights")} `}(
                  {reservation?.sailings?.[0]?.sailing_code ?? ""})
                </p>
              </div>

              {/* Reservation item containing lead guest information */}
              <div className={styles.reservationItem}>
                <p className={styles.guestInfo_primary}>
                  {reservation?.lead_title ?? ""}{" "}
                  {reservation?.lead_given_name ?? ""}{" "}
                  {reservation?.lead_lastname ?? ""}
                </p>

                <p className={styles.guestInfo_secondary}>
                  {reservation?.lead_email ?? ""}
                </p>

                <p className={styles.guestInfo_secondary}>
                  {reservation?.lead_phone ?? ""}
                </p>
              </div>

              {/* Reservation item containing total price and rate code */}
              <div className={styles.reservationItem}>
                <p className={styles.priceInfo_price}>
                  {currencyToFormat(Number(reservation?.total_price ?? "0"))}
                </p>

                <p className={styles.priceInfo_description}>
                  {reservation?.sailings?.[0]?.rate_code ?? ""}
                </p>
              </div>
            </div>
          </div>

          {/* Container for passengers table */}
          <div className={styles.tableContainer}>
            <h2 className={styles.tableContainer_title}>{t("passengers")}</h2>

            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("date of birth")}</th>
                    <th>{t("language")}</th>
                    <th>{t("price")}</th>
                  </tr>
                </thead>

                <tbody>
                  {(reservation?.sailings?.[0]?.cabins ?? []).map((cabin) => {
                    return (cabin?.guests ?? []).map((guest) => {
                      const {
                        uuid,
                        title,
                        given_name,
                        lastname,
                        date_of_birth,
                        nationality,
                        pricing,
                      } = guest;

                      return (
                        <tr key={uuid}>
                          <td>
                            <p>{`${title} ${given_name} ${lastname}`}</p>
                          </td>

                          <td>
                            <p>{dayjs(date_of_birth).format(date_format)}</p>
                          </td>

                          <td>
                            <p>{`${nationality ?? t("Not specified")}`}</p>
                          </td>

                          <td>
                            <p className={styles.textContent}>
                              <span>{`${t("Fare")}: `}</span>
                              {`${pricing.fare}`}
                            </p>

                            <p className={styles.textContent}>
                              <span>{`${t("Taxes")}: `}</span>
                              {`${pricing.gft}`}
                            </p>

                            <p className={styles.textContent}>
                              <span>{`${t("Extras")}: `}</span>
                              {`${pricing.extras}`}
                            </p>
                          </td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Container for cabins information */}
          <div className={styles.tableContainer}>
            <h2 className={styles.tableContainer_title}>{t("cabins")}</h2>

            <div className={styles.info}>
              {(reservation?.sailings?.[0]?.cabins ?? []).map((cabin) => {
                const { guests, deck_name, cabin_number, grade_code } = cabin;
                const grade = grades?.find((el) => el?.code === grade_code);

                return (
                  <div key={cabin.uuid} className={styles.cabin}>
                    <div className={styles.guests}>
                      {(guests ?? []).map((guest) => {
                        const { uuid, title, given_name, lastname } = guest;

                        return (
                          <div key={uuid} className={styles.guest}>
                            <p>{`${title} ${given_name} ${lastname}`}</p>
                          </div>
                        );
                      })}
                    </div>

                    <div className={styles.deckInfo}>
                      <p>
                        <span>{`${t("Deck")}: `}</span>

                        {deck_name.split(" ")[1]}
                      </p>

                      <p>
                        <span>{`${t("Stateroom")}: `}</span>
                        {cabin_number}
                      </p>
                    </div>

                    <p className={styles.gradeName}>{grade?.name ?? ""}</p>

                    <p className={styles.gradeDescription}>
                      {grade?.descriptions?.[0]?.description ?? ""}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Container for itinerary information */}
          <div className={styles.tableContainer}>
            <div>
              <h2 className={styles.tableContainer_title}>{t("itinerary")}</h2>

              <p className={styles.tableContainer_subtitle}>
                {sailing.cruise_name}
              </p>
            </div>

            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("port")}</th>
                    <th>{t("arrive")}</th>
                    <th>{t("depart")}</th>
                    <th>{t("tender")}</th>
                  </tr>
                </thead>

                <tbody>
                  {sailing.itinerary.map((item) => {
                    const {
                      date,
                      port_name,
                      port_code,
                      arrive_time,
                      depart_time,
                      tender,
                    } = item;

                    return (
                      <tr key={nanoid()}>
                        <td>
                          <p>{dayjs(date).format(date_format)}</p>
                        </td>

                        <td>
                          <p>{`${port_name} (${port_code})`}</p>
                        </td>

                        <td>
                          <p>{`${arrive_time ?? "-"}`}</p>
                        </td>

                        <td>
                          <p>{`${depart_time ?? "-"}`}</p>
                        </td>

                        <td>
                          <p>{t(tender ? "Yes" : "No")}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Container for payment schedule information */}
          <div className={styles.paymentContainer}>
            <div className={styles.tableContainer}>
              <div>
                <h2 className={styles.tableContainer_title}>
                  {t("payment schedule")}
                </h2>

                <p className={styles.tableContainer_subtitle}>
                  {`${t("Next payment of")} ${currencyToFormat(Number(reservation?.next_payment_outstanding ?? "0"))} ${t("on")} ${dayjs(reservation?.next_payment_due ?? null).format(date_format)}`}
                </p>
              </div>

              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("payment date")}</th>
                      <th>{t("payment amount")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(reservation?.payment_schedule ?? []).map((item) => {
                      const { due_date, amount } = item;

                      return (
                        <tr key={nanoid()}>
                          <td>
                            <p>{dayjs(due_date).format(date_format)}</p>
                          </td>

                          <td>
                            <p>{currencyToFormat(Number(amount))}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Container for transaction information */}
            <div className={styles.tableContainer}>
              <div>
                <h2 className={styles.tableContainer_title}>
                  {t("transactions")}
                </h2>

                <p className={styles.tableContainer_subtitle}>
                  {`${t("Last transaction of")} ${currencyToFormat(Number(reservation?.transactions?.[reservation?.transactions?.length - 1]?.amount ?? "0"))} ${t("was")} ${dayjs(reservation?.transactions?.[reservation?.transactions?.length - 1]?.made_on ?? null).format(date_format)}`}
                </p>
              </div>

              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("transaction id")}</th>
                      <th>{t("amount")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(reservation?.transactions ?? []).map((item) => {
                      const { card_clearance, amount } = item;

                      return (
                        <tr key={nanoid()}>
                          <td>
                            <p>{card_clearance?.transaction_id ?? ""}</p>
                          </td>

                          <td>
                            <p>{currencyToFormat(Number(amount ?? "0"))}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

// Exporting the PaymentConfirmation component as default.
export default PaymentConfirmation;
