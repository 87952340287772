// Import CSS module styles specific to this component.
import styles from "./index.module.scss";

// Define the MainLayout functional component. This component accepts children,
// allowing it to wrap other components or content passed to it.
function MainLayout({ children }: React.PropsWithChildren) {
  // Render the main layout structure.
  return (
    // The main HTML element serves as the root of this layout component,
    // with a class assigned from the imported styles for consistent styling.
    <main className={styles.container}>
      {/* A div for a hero section, often used for showcasing key visual or introductory content.
      It's styled through the 'hero' class from the imported styles. */}
      <div className={styles.hero} />

      {/* A section element intended to contain the main content of the layout. 
      This is where the children components or elements passed to MainLayout will be rendered.
      It uses a 'content' class from the styles for specific styling. */}
      <section className={styles.content}>{children}</section>
    </main>
  );
}

// Export the MainLayout component to make it available for use in other parts of the application.
export default MainLayout;
