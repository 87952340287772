import { useMemo, useState } from "react"; // Importing necessary hooks from React
import { useTranslation } from "react-i18next"; // Importing the useTranslation hook for language translation
import classNames from "classnames"; // Importing classNames for conditional CSS classes

import styles from "./index.module.scss"; // Importing the CSS module for styling
import Checkbox from "../../../../shared/Checkbox"; // Importing the Checkbox component
import { useTypedSelector } from "../../../../../store/store";

// Interface for props of the PriceType component
interface IPriceTypeProps {
  full: number; // Full amount
  deposit: number; // Deposit amount
  currency: string; // Currency
  onPriceTypeChange: (priceType: "full" | "deposit") => void; // Function to handle changes in price type
}

/**
 * Functional component for rendering price type options.
 * This component displays two options: full amount and deposit amount,
 * along with corresponding prices and checkboxes for selection.
 *
 * @param {number} full - The full amount.
 * @param {number} deposit - The deposit amount.
 * @param {string} currency - The currency.
 * @param {Function} onPriceTypeChange - Function to handle changes in price type.
 * @returns {JSX.Element} PriceType component JSX structure.
 */
function PriceType({
  full,
  deposit,
  currency,
  onPriceTypeChange,
}: IPriceTypeProps) {
  const { t } = useTranslation(); // Translation function from react-i18next
  const { api_language } = useTypedSelector((state) => state.environment);

  // State to manage the selected price type
  const [priceType, setPriceType] = useState<"full" | "deposit">("full");

  // Memoized formatted full and deposit amounts based on currency
  const { formattedFull, formattedDeposit } = useMemo(() => {
    // Formatter for currency
    const formatter = new Intl.NumberFormat(api_language || "en-US", {
      style: "currency",
      currency,
      maximumFractionDigits: 2,
    });

    return {
      // Formatted full amount
      formattedFull: formatter.format(full),
      // Formatted deposit amount (or "N/A" if full amount equals deposit amount or is zero)
      formattedDeposit:
        full === deposit || full === 0 ? "N/A" : formatter.format(deposit),
    };
  }, [full, deposit, currency]);

  // Function to handle changes in price type
  const handlePriceTypeChange = (type: typeof priceType) => () => {
    // If attempting to select deposit and deposit is not applicable, return early
    if (type === "deposit" && formattedDeposit === "N/A") {
      return;
    }

    // Set the selected price type and call the provided callback
    setPriceType(type);
    onPriceTypeChange(type);
  };

  // JSX structure for rendering price type options
  return (
    <div className={styles.container}>
      {/* Render full amount option */}
      <div
        onClick={handlePriceTypeChange("full")}
        className={classNames(styles.item, {
          [styles.item_active]: priceType === "full", // Add active class if full amount is selected
        })}>
        {/* Render full amount information */}
        <div className={styles.priceInfo}>
          <p className={styles.title}>{t("full amount")}</p>
          <p className={styles.price}>{formattedFull}</p>
        </div>

        {/* Render Checkbox for full amount */}
        <div className={styles.checkboxContainer}>
          <Checkbox
            value={priceType === "full"} // Set checkbox value based on selected price type
            onChange={handlePriceTypeChange("full")} // Handle checkbox change
          />
        </div>
      </div>

      {/* Render deposit payment option */}
      <div
        onClick={handlePriceTypeChange("deposit")}
        className={classNames(styles.item, {
          [styles.item_active]: priceType === "deposit", // Add active class if deposit payment is selected
          [styles.item_disabled]: formattedDeposit === "N/A", // Add disabled class if deposit is not applicable
        })}>
        {/* Render deposit payment information */}
        <div className={styles.priceInfo}>
          <p className={styles.title}>{t("deposit payment")}</p>
          <p className={styles.price}>{formattedDeposit}</p>
        </div>

        {/* Render Checkbox for deposit payment */}
        <div className={styles.checkboxContainer}>
          <Checkbox
            value={priceType === "deposit"} // Set checkbox value based on selected price type
            onChange={handlePriceTypeChange("deposit")} // Handle checkbox change
          />
        </div>
      </div>
    </div>
  );
}

// Export the PriceType component
export default PriceType;
