import { PayloadAction, createSlice } from "@reduxjs/toolkit"; // Import necessary modules

import {
  destroySession,
  initApiToken,
  initPosSession,
} from "../actions/sessionActions"; // Import actions related to session initialization

import { SessionApi } from "../services/SessionService";

// Define the shape of the session state
export interface ISessionState {
  token: string; // JWT token for authentication
  name: string;
  agent: string;
  agency: string;
  sessionKey: string; // Session key for API requests
  isLoading: boolean; // Flag indicating if session initialization is in progress
  isInitialized: boolean; // Flag indicating if session is initialized
}

// Define the initial state for the session slice
const initialState: ISessionState = {
  token: "",
  name: "",
  agent: "",
  agency: "",
  sessionKey: "",
  isLoading: false,
  isInitialized: false,
};

// Create the session slice using createSlice function
const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    // Reducer for updating the token in the session state
    updateToken(state: ISessionState, action: PayloadAction<string>) {
      state.token = action.payload;
      state.isInitialized = true;
    },
    updateAgentName(state: ISessionState, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    updateSessionKey(state: ISessionState, action: PayloadAction<string>) {
      state.sessionKey = action.payload;
    },
    updateAgency(state: ISessionState, action: PayloadAction<string>) {
      state.agency = action.payload;
    },
    updateAgent(state: ISessionState, action: PayloadAction<string>) {
      state.agent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Pending action for initializing API token
      .addCase(initApiToken.pending, (state) => {
        state.isInitialized = false;
      })
      // Pending action for initializing POS session
      .addCase(initPosSession.pending, (state) => {
        state.isLoading = true;
        state.isInitialized = false;
      })
      // Fulfilled action for initializing API token
      .addCase(initApiToken.fulfilled, (state, action) => {
        state.token = action.payload?.token ?? "";
        state.isInitialized = true;
      })
      // Fulfilled action for initializing POS session
      .addCase(initPosSession.fulfilled, (state, action) => {
        state.sessionKey = action.payload?.key ?? "";
        state.isLoading = false;
        state.isInitialized = true;
      })
      .addCase(destroySession.fulfilled, (state) => {
        state.name = "";
        state.agent = "";
        state.agency = "";
        state.sessionKey = "";
      })
      .addCase(initApiToken.rejected, (state) => {
        state.isInitialized = true;
      })
      // Rejected action for initializing POS session
      .addCase(initPosSession.rejected, (state) => {
        state.isLoading = false;
      })
      .addMatcher(
        SessionApi.endpoints.initAgentSession.matchPending,
        (state) => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        SessionApi.endpoints.initAgentSession.matchFulfilled,
        (state, action) => {
          state.name = action.payload?.name ?? "";
          state.agent = action.payload?.id ?? "";
          state.agency = action.payload?.agency ?? "";
          state.isLoading = false;
        },
      )
      .addMatcher(
        SessionApi.endpoints.initAgentSession.matchRejected,
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

// Export the action creators for updating token and session key
export const {
  updateToken,
  updateAgentName,
  updateSessionKey,
  updateAgency,
  updateAgent,
} = sessionSlice.actions;

// Export the reducer function for the session slice
export default sessionSlice.reducer;
