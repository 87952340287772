import { createSlice } from "@reduxjs/toolkit"; // Import necessary modules

import { SearchAPI } from "../services/SearchService"; // Import the SearchAPI service from the services folder

// Define interfaces for various data types used in the slice
export interface Market {
  gft: string;
  extra: string;
  price: string;
  single: string;
}

export interface Grade {
  code: string;
  colour_code: string;
  decks: Array<{ code: string; name: string }>;
  descriptions: Array<{ category: string; description: string; title: string }>;
  images: string[];
  maximum_occupancy: number;
  meta_category: string;
  minimum_occupancy: number;
  name: string;
  size: string;
  price?: string;
  single: number;
  double: number;
  triple: number;
  quadruple: number;
}

export interface Deck {
  code: string;
  name: string;
  images: string[];
}

export interface Prices {
  child: string;
  extra: string;
  fourth: string;
  gft: string;
  grade: string;
  infant: string;
  ncf: string;
  single: string;
  standard: string;
  third: string;
}

export interface Price {
  currency: string;
  market: string;
  rate_code: string;
  rate_name: string;
  rate_restriction: string;
  rate_description: string[];
  prices: Prices[];
  lowest_fare: boolean;
}

export interface ICruise {
  source: string;
  embark: string;
  code: string;
  ship: {
    code: string;
    name: string;
    images: string[];
    grades: Grade[];
    decks: Deck[];
  };
  cruise: {
    code: string;
    name: string;
    nights: number;
    images: string[];
    map: string;
    itinerary: Array<{
      day: number;
      port: string;
      tender: boolean;
      country: string;
      description: string;
      arrive_time: string | null;
      depart_time: string | null;
      extra_information: null;
    }>;
    tags: null;
    countries: string[];
  };
  markets: {
    suite: Market;
    inside: Market;
    balcony: Market;
    outside: Market;
  };
  pricing: Price[];
}

export interface SearchState {
  searchParams: {
    start_date?: string;
    end_date?: string;
    tag?: string;
    night?: string;
  };
  results: ICruise[] | undefined;
  cruise: ICruise | undefined;
  isCruiseLoading: boolean;
  isLoading: boolean;
  error: boolean;
}

// Define initial state for the search slice
const initialState: SearchState = {
  searchParams: {},
  results: undefined,
  cruise: undefined,
  isCruiseLoading: true,
  isLoading: true,
  error: false,
};

// Create the search slice using createSlice function
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Pending action for initializing search results
      .addMatcher(
        SearchAPI.endpoints.initSearchResults.matchPending,
        (state) => {
          state.isLoading = true; // Set isLoading flag to true
        },
      )
      // Fulfilled action for initializing search results
      .addMatcher(
        SearchAPI.endpoints.initSearchResults.matchFulfilled,
        (state, action) => {
          state.results = action.payload; // Set search results
          state.isLoading = false; // Set isLoading flag to false
        },
      )
      // Rejected action for initializing search results
      .addMatcher(
        SearchAPI.endpoints.initSearchResults.matchRejected,
        (state) => {
          state.isLoading = false; // Set isLoading flag to false
          state.error = true; // Set error flag to true
        },
      )
      // Pending action for initializing cruise details
      .addMatcher(SearchAPI.endpoints.initCruise.matchPending, (state) => {
        state.isCruiseLoading = true; // Set isCruiseLoading flag to true
      })
      // Fulfilled action for initializing cruise details
      .addMatcher(
        SearchAPI.endpoints.initCruise.matchFulfilled,
        (state, action) => {
          state.cruise = action.payload; // Set cruise details
          state.isCruiseLoading = false; // Set isCruiseLoading flag to false
        },
      )
      // Rejected action for initializing cruise details
      .addMatcher(SearchAPI.endpoints.initCruise.matchRejected, (state) => {
        state.isCruiseLoading = false; // Set isCruiseLoading flag to false
        state.error = true; // Set error flag to true
      });
  },
});

// Export the reducer function for the search slice
export default searchSlice.reducer;
