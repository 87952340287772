/**
 * Converts a country code to its full name using Intl.DisplayNames API.
 * @param {string} code - The country code to be converted.
 * @returns {string} The full name of the country corresponding to the provided code.
 */
export const countryCodeToFullName = (code: string) => {
  const country = new Intl.DisplayNames(["en"], { type: "region" }).of(code);

  return country ?? code;
};
