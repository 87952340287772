import { reservationApi } from "./apiSingleton"; // Importing reservationApi from apiSingleton file

import {
  ReservationInterface,
  ReservationListInterface,
} from "../slices/reservationSlice"; // Importing necessary interfaces from reservationSlice file

// Interface defining the shape of the request to initialize reservation list
export interface IInitReservationListRequest {
  channel: string;
  channel_partner: string;
  agency: string;
  ship_code?: string;
  sailing_code?: string;
  lastname?: string;
}

// Response type for the reservation list
export type ReservationListResponse = ReservationListInterface[];

// Creating ReservationApi by injecting endpoints into reservationApi
export const ReservationApi = reservationApi.injectEndpoints({
  endpoints: (builder) => ({
    // Defining endpoint for initializing reservation list
    initReservationList: builder.query<
      ReservationListResponse,
      IInitReservationListRequest
    >({
      query: (params) => ({
        url: `res/reservation-list/`, // API endpoint for initializing reservation list
        method: "GET", // HTTP method
        params, // Request parameters
      }),
    }),

    // Defining endpoint for retrieving a specific reservation
    retrieveReservation: builder.query<
      ReservationInterface,
      { pnr: string; agency: string }
    >({
      query: (params) => ({
        url: `/res/reservation-list/${params.pnr}?agency=${params.agency}`, // API endpoint for retrieving reservation
        method: "GET", // HTTP method
      }),
    }),
  }),
});

// Destructuring useful hooks from ReservationApi
export const {
  useLazyInitReservationListQuery,
  useLazyRetrieveReservationQuery,
} = ReservationApi;
