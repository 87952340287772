// Import necessary modules and components
import { useEffect, useMemo, useState } from "react"; // React hooks for managing component state and lifecycle
import { useLocation, useNavigate } from "react-router-dom"; // React Router hooks for navigation and accessing URL parameters
import dayjs from "dayjs"; // Library for date manipulation
import { useTranslation } from "react-i18next"; // Translation hook for multilingual support

import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Redux hooks for typed dispatch and selector
import { showModal } from "../../../store/slices/modalSlice"; // Redux action to show a modal
import { clearGuest } from "../../../store/slices/guestsSlice"; // Redux action to clear guest data

import { validateRooms } from "../../../utils/validation"; // Function for form validation
import MODAL from "../../../utils/constants/modal"; // Constants for modal types

import LoadingContainer from "../../containers/LoadingContainer"; // Container component for loading state
import CustomCarousel from "../../shared/Carousel"; // Custom Carousel component for image slideshow
import Button from "../../shared/Button"; // Custom Button component
import Counter from "../../shared/Counter"; // Custom Counter component for numeric input

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Define types for input change handler, validation inputs, and input state
type InputChangeHandler = Record<string, string>;

interface IValidationInputs {
  rooms: string;
}

interface IInputs {
  rooms: { value: string; errorMessage: string };
}

// Component for displaying when the cruise data is not found
function DummyCruise() {
  const { t } = useTranslation();

  return <p>{t("Cruise not found")}</p>;
}

// Main Cruise component
function Cruise() {
  // Define hooks for managing component state and accessing router-related data
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { pathname, search } = useLocation();

  const { cruise, isCruiseLoading } = useTypedSelector((state) => state.search);
  const { api_language } = useTypedSelector((state) => state.environment);

  // Define state variables for input values and errors
  const [inputs, setInputs] = useState<IInputs>({
    rooms: { value: "1", errorMessage: "" },
  });

  // Calculate price based on cruise data and selected rooms
  const price = useMemo(() => {
    if (!cruise) {
      return 0;
    }

    const prices = Object.values(cruise.markets)
      .map((market) => parseFloat(market.price))
      .filter((price) => !isNaN(price) && price !== 0);

    const price = Math.min(...prices);

    const formatted = price.toLocaleString(api_language || "en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatted;
  }, [cruise]);

  // Function to show itinerary modal
  const handleShowModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY, scrollY: 0 }));
  };

  // Function to handle input change
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));
  };

  // Function to handle form errors
  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (updatedInputs[errorKey as keyof IInputs]) {
        updatedInputs[errorKey as keyof IInputs].errorMessage =
          errors[errorKey as keyof IValidationInputs];
      }
    });

    setInputs(updatedInputs);
  };

  // Function to handle form submission
  const handleSubmit = () => {
    validateRooms({
      data: {
        rooms: inputs.rooms.value,
      },
      onSuccess: async (validData: IValidationInputs) => {
        navigate(`${pathname}/rooms/${search}&rooms=${validData.rooms}&room=1`);
        scrollTo(0, 0);
      },
      onError: (errors: IValidationInputs) => handleFormError(errors),
    });
  };

  // If data is not found displaying component with no data
  if (!isCruiseLoading && !cruise) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <DummyCruise />
        </div>
      </div>
    );
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(clearGuest());
  }, []);

  return (
    <LoadingContainer isLoading={isCruiseLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          {/* Display images, ship details, price, stateroom selection */}
          <div className={styles.imagesContainer}>
            <CustomCarousel
              items={cruise?.ship.images ?? []}
              className={styles.carousel}
            />

            <Button
              className={styles.itineraryButton}
              label={t("view itinerary")}
              variant="secondary"
              icon="plus"
              onClick={handleShowModal}
            />

            <p className={styles.name}>{cruise?.cruise.name ?? ""}</p>
          </div>

          {/* Display ship details */}
          <table className={styles.details}>
            <tbody>
              <tr>
                <td>{t("SHIP NAME")}</td>
                <td>{cruise?.ship.name}</td>
              </tr>

              <tr>
                <td>{t("DEPARTS")}</td>
                <td>{dayjs(cruise?.embark ?? "").format("MM-DD-YYYY")}</td>
              </tr>
            </tbody>
          </table>

          {/* Display price and stateroom selection */}
          <div className={styles.price}>
            <span className={styles.price_from}>From</span>

            <span className={styles.price_value}>{price}</span>
          </div>

          <div className={styles.staterooms}>
            <p className={styles.staterooms_title}>
              {t("How many staterooms do you need?")}
            </p>

            <span className={styles.staterooms_subtitle}>
              {t("Most rooms sleep 4 guests")}
            </span>

            {/* Custom components for choosing amount of rooms */}
            <Counter
              value={inputs.rooms.value} // Current value from min to max
              valueKey="rooms"
              errorMessage={inputs.rooms.errorMessage}
              min={1} // min value
              onChange={handleInputChange}
            />
          </div>

          {/* Continue button */}
          <Button
            label={t("continue to staterooms")}
            className={styles.submitButton}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </LoadingContainer>
  );
}

// Exporting the Cruise component as default.
export default Cruise;
