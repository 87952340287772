// Importing Input component for form inputs.
import Input from "../../shared/Input";
// Importing necessary hooks from React.
import { useEffect, useMemo, useRef, useState } from "react";
// Importing DatePicker component and its handler type for date picking.
import DatePicker, { DatePickerHandler } from "../../shared/DatePicker";
// Importing Content component to display the list of bookings.
import Content from "./molecules/Content";
// Importing useTranslation hook from react-i18next for internationalization.
import { useTranslation } from "react-i18next";

// Importing styles from CSS module.
import styles from "./index.module.scss";
// Importing useNavigate hook from react-router-dom for navigation.
import { useNavigate } from "react-router-dom";
// Importing SEARCH constant from utilities to use in navigation.
import { SEARCH } from "../../../utils/constants/routes";
// Importing useLazyInitReservationListQuery hook for initiating reservation list query.
import { useLazyInitReservationListQuery } from "../../../store/services/ReservationService";
// Importing useTypedSelector hook for accessing Redux state.
import { useTypedSelector } from "../../../store/store";
// Importing LoadingContainer component to display loading state.
import LoadingContainer from "../../containers/LoadingContainer";
// Importing ReservationListInterface from reservation slice.
import { ReservationListInterface } from "../../../store/slices/reservationSlice";
// Importing dayjs for date manipulation.
import dayjs from "dayjs";

// Interface for input props.
interface InputInterface {
  value: string[];
  errorMessage?: string;
}

// Interface for sort-on state.
interface SortOnInterface {
  type: string;
  first: boolean;
}

// Interface for search inputs state.
interface SearchInputsInterface {
  [key: string]: InputInterface;
  bookingName: InputInterface;
  embarkDate: InputInterface;
  yachtName: InputInterface;
}

// PreviousBookings component definition.
function PreviousBookings() {
  // Using the useTranslation hook to get the t function for translating text.
  const { t } = useTranslation();

  // Using useNavigate hook for navigation.
  const navigate = useNavigate();
  // useRef hook to get a reference to the DatePicker component.
  const datePickerRef = useRef<DatePickerHandler>(null);

  // Getting the booking list from Redux state.
  const bookingList = useTypedSelector(
    (state) => state.reservation.reservationList,
  );

  // State for storing and setting the booking list state.
  const [bookingListState, setBookingListState] = useState<
    ReservationListInterface[]
  >([]);

  // Getting session data from Redux state.
  const session = useTypedSelector((state) => state.session);

  // State for handling search inputs.
  const [searchInputs, setSearchInputs] = useState<SearchInputsInterface>({
    bookingName: { value: [""] },
    embarkDate: {
      value: [],
      errorMessage: "",
    },
    yachtName: { value: [""] },
  });

  // Function to check if a given date falls within a specified date range.
  const checkDateFilter = (
    startDate: string,
    endDate: string,
    checkDate: string,
  ) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const check = new Date(checkDate);

    return check >= start && check <= end;
  };

  // State for filtered booking list.
  const [filterBookingList, setFilterBookingList] = useState<
    ReservationListInterface[]
  >([]);

  // Effect to filter booking list based on search inputs.
  useEffect(() => {
    const filterTotal = bookingList.filter((item) => {
      const checkBookingName = searchInputs.bookingName.value[0]
        ? item.lead_given_name
            .toLowerCase()
            .includes(searchInputs.bookingName.value[0].toLowerCase())
        : false;
      const checkYachName = searchInputs.yachtName.value[0]
        ? item.cruise_name
            .toLowerCase()
            .includes(searchInputs.yachtName.value[0].toLowerCase())
        : false;

      const checkDate = checkDateFilter(
        searchInputs.embarkDate.value[0],
        searchInputs.embarkDate.value[1],
        item.embarkation_date,
      );

      return checkBookingName || checkYachName || checkDate;
    });

    setFilterBookingList(filterTotal);
  }, [searchInputs]);

  // Memo to determine if any filter is being used.
  const isUsingFilter = useMemo(() => {
    for (const key in searchInputs) {
      const value = searchInputs[key].value;

      if (value.length !== 0 && value[0] !== "") {
        return true;
      }
    }

    return false;
  }, [searchInputs]);

  // State for sorting.
  const [sortOn, setSortOn] = useState({ type: "", first: false });

  // Function to sort booking list by criteria.
  const sortByCriteria = (
    data: ReservationListInterface[],
    type: string,
    sortOn: SortOnInterface,
    setSortOn: (args: SortOnInterface) => void,
    sortFunction: (
      a: ReservationListInterface,
      b: ReservationListInterface,
    ) => number,
  ) => {
    if (sortOn.type === type) {
      const sortedData = data.sort(sortFunction);

      setSortOn({ type, first: !sortOn.first });

      return sortedData;
    } else {
      const sortedData = data.sort(sortFunction);

      setSortOn({ type, first: false });

      return sortedData;
    }
  };

  // Function to sort booking list by type.
  const sortBookingList = (type: string) => {
    switch (type) {
      case "passenger_name":
        setFilterBookingList((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) =>
              !sortOn.first
                ? a.lead_given_name.localeCompare(b.lead_given_name)
                : b.lead_given_name.localeCompare(a.lead_given_name),
          ),
        );
        setBookingListState((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) =>
              !sortOn.first
                ? a.lead_given_name.localeCompare(b.lead_given_name)
                : b.lead_given_name.localeCompare(a.lead_given_name),
          ),
        );
        break;
      case "embarkation_date":
        setFilterBookingList((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) => {
              const dateA = dayjs(a.embarkation_date);
              const dateB = dayjs(b.embarkation_date);

              return !sortOn.first ? dateA.diff(dateB) : dateB.diff(dateA);
            },
          ),
        );
        setBookingListState((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) => {
              const dateA = dayjs(a.embarkation_date);
              const dateB = dayjs(b.embarkation_date);

              return !sortOn.first ? dateA.diff(dateB) : dateB.diff(dateA);
            },
          ),
        );
        break;
    }
  };

  // Function to handle input change.
  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string | string[];
    valueKey?: string;
  }) => {
    if (valueKey) {
      setSearchInputs((prev: SearchInputsInterface) => {
        return {
          ...prev,
          [valueKey]: {
            errorMessage: "",
            value: Array.isArray(value) ? value : [value],
          },
        };
      });
    }
  };

  // Function to reset all filters.
  const resetFiltersHandler = () => {
    setSearchInputs({
      bookingName: { value: [""] },
      embarkDate: {
        value: [],
        errorMessage: "",
      },
      yachtName: { value: [""] },
      expeditionType: { value: [] },
    });

    if (datePickerRef.current) {
      datePickerRef.current.handleResetDate();
    }
  };

  // Using useLazyInitReservationListQuery hook to initiate reservation list query.
  const [getReservation] = useLazyInitReservationListQuery();
  // State for tracking if reservation list is loading.
  const [isReservationLoading, setReservationLoading] = useState(true);

  // Effect to get reservation list.
  useEffect(() => {
    getReservation({
      channel: "CS",
      channel_partner: session.agency,
      agency: session.agent,
    })
      .then((res) => {
        if (!res.data) return;
        setBookingListState(res.data);
      })
      .finally(() => {
        setReservationLoading(false);
      });
  }, []);

  // Returning the JSX of the component.
  return (
    // Loading container to display loading state.
    <LoadingContainer isLoading={isReservationLoading}>
      {/* Main container for the component. */}
      <div className={styles.container}>
        {/* Content container. */}
        <div className={styles.content}>
          {/* Header for filters. */}
          <div className={styles.filtersHeader}>
            {/* Title for the page. */}
            <h1>{t("PREVIOUS BOOKINGS")}</h1>
            {/* Button to start a new booking. */}
            <button
              className={styles.startBooking}
              onClick={() => navigate(SEARCH)}>
              {t("START A BOOKING")}
            </button>
          </div>
          {/* Instructions for the user. */}
          <div className={styles.filtersHeader}>
            <span>{t("Click on a booking to find out more information.")}</span>
          </div>
          {/* Filter options header. */}
          <div className={styles.filtersHeader}>
            <span>{t("FILTER BOOKINGS BY")}</span>
            {/* Button to reset all filters. */}
            <button
              className={styles.resetFilters}
              onClick={resetFiltersHandler}>
              {t("RESET FILTERS")}
            </button>
          </div>
          {/* Form for search filters. */}
          <form className={styles.form}>
            {/* Input for booking name search. */}
            <Input
              value={searchInputs.bookingName.value[0]}
              valueKey="bookingName"
              placeholder={t("by name")}
              errorMessage={searchInputs.bookingName.errorMessage}
              onChange={handleInputChange}
              className={styles.input}
            />

            {/* Date picker for embarkation date range search. */}
            <DatePicker
              valueKey="embarkDate"
              placeholder={t("embark date")}
              returnFormat="YYYY-MM"
              defaultDate={searchInputs.embarkDate.value}
              errorMessage={searchInputs.embarkDate.errorMessage}
              onChange={handleInputChange}
              ref={datePickerRef}
              className={styles.inputDate}
              mode="range"
              minDate={dayjs(dayjs().subtract(1, "month"))}
            />

            {/* Input for yacht name search. */}
            <Input
              value={searchInputs.yachtName.value[0]}
              valueKey="yachtName"
              placeholder={t("yacht name")}
              errorMessage={searchInputs.yachtName.errorMessage}
              onChange={handleInputChange}
              className={styles.input}
            />
          </form>
          {/* Component to display the list of bookings. */}
          <Content
            bookingList={isUsingFilter ? filterBookingList : bookingListState}
            sortBookingList={sortBookingList}
          />
        </div>
      </div>
    </LoadingContainer>
  );
}

// Exporting the PreviousBookings component as default.
export default PreviousBookings;
