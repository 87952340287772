/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Utility for conditionally applying class names.
import classNames from "classnames";
// Hook for internationalization support.
import { useTranslation } from "react-i18next";

// Constants for routing and helper function for currency formatting.
import { CURRENT_BOOKINGS, ROOT } from "../../../utils/constants/routes";
import { currencyToFormat } from "../../../utils/helpers/currency";

// Custom hook for fetching reservation data.
import { useLazyRetrieveReservationQuery } from "../../../store/services/ReservationService";
// Hook for accessing Redux store state.
import { useTypedSelector } from "../../../store/store";

// Components for loading indicator, breadcrumbs, and various UI elements.
import LoadingContainer from "../../containers/LoadingContainer";
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs";

// CSS module styles specific to this component.
import styles from "./index.module.scss";
// Library for date manipulation.
import dayjs from "dayjs";
// Utility for generating unique IDs (e.g., for keys in a list).
import { nanoid } from "@reduxjs/toolkit";

// The main functional component definition.
function Booking() {
  // Hooks for navigation and translation.
  const navigate = useNavigate();
  const { t } = useTranslation();
  // Hook for initiating the lazy fetch of reservation details.
  const [getReservation] = useLazyRetrieveReservationQuery();

  // Accessing the booking identifier (PNR) from the URL parameters.
  const { pnr = "" } = useParams();

  // Redux store selectors.
  const { agency } = useTypedSelector((state) => state.session);
  const { date_format } = useTypedSelector((state) => state.environment);

  // Selector for the current reservation state.
  const reservation = useTypedSelector(
    (state) => state.reservation.reservation,
  );

  // Memoized value to access the first sailing of the reservation.
  const sailing = useMemo(() => reservation?.sailings?.[0], [reservation]);

  // State hook for tracking the loading status of the reservation data.
  const [isLoading, setIsLoading] = useState(true);

  // Effect hook to fetch reservation data on component mount.
  useEffect(() => {
    getReservation({ pnr, agency }).finally(() => {
      setIsLoading(false); // Update loading state once the data is fetched.
    });
  }, []);

  // Component rendering.
  return (
    // Wrapper component to display a loading indicator while data is being fetched.
    <LoadingContainer isLoading={isLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.crumbsAndButton}>
            <CustomBreadcrumbs
              crumbsList={[
                [ROOT, "HOME"],
                [CURRENT_BOOKINGS, "CURRENT BOOKINGS"],
                pnr ?? "BOOKING",
              ]}
            />

            {/* Button to navigate back to the previous page. */}
            <button className={styles.backButton} onClick={() => navigate(-1)}>
              {t("Back to all bookings")}
            </button>
          </div>

          <div className={styles.tableContainer}>
            <h2 className={styles.tableContainer_title}>{t("passengers")}</h2>

            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("date of birth")}</th>
                    <th>{t("language")}</th>
                    <th>{t("price")}</th>
                  </tr>
                </thead>

                <tbody>
                  {(reservation?.sailings?.[0]?.cabins ?? []).map((cabin) => {
                    return (cabin?.guests ?? []).map((guest) => {
                      const {
                        uuid,
                        title,
                        given_name,
                        lastname,
                        date_of_birth,
                        nationality,
                        pricing,
                      } = guest;

                      return (
                        <tr key={uuid}>
                          <td>
                            <p>{`${title} ${given_name} ${lastname}`}</p>
                          </td>

                          <td>
                            <p>{dayjs(date_of_birth).format(date_format)}</p>
                          </td>

                          <td>
                            <p>{`${nationality ?? t("Not specified")}`}</p>
                          </td>

                          <td>
                            <p className={styles.textContent}>
                              <span>{`${t("Fare")}: `}</span>
                              {`${currencyToFormat(pricing.fare)}`}
                            </p>

                            <p className={styles.textContent}>
                              <span>{`${t("Taxes")}: `}</span>
                              {`${currencyToFormat(pricing.gft)}`}
                            </p>

                            <p className={styles.textContent}>
                              <span>{`${t("Extras")}: `}</span>
                              {`${currencyToFormat(pricing.extras)}`}
                            </p>
                          </td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <div>
              <h2 className={styles.tableContainer_title}>{t("itinerary")}</h2>

              <p className={styles.tableContainer_subtitle}>
                {sailing.cruise_name}
              </p>
            </div>

            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("port")}</th>
                    <th>{t("arrive")}</th>
                    <th>{t("depart")}</th>
                    <th>{t("tender")}</th>
                  </tr>
                </thead>

                <tbody>
                  {sailing.itinerary.map((item) => {
                    const {
                      date,
                      port_name,
                      port_code,
                      arrive_time,
                      depart_time,
                      tender,
                    } = item;

                    return (
                      <tr key={nanoid()}>
                        <td>
                          <p>{dayjs(date).format(date_format)}</p>
                        </td>

                        <td>
                          <p>{`${port_name} (${port_code})`}</p>
                        </td>

                        <td>
                          <p>{`${arrive_time ?? "-"}`}</p>
                        </td>

                        <td>
                          <p>{`${depart_time ?? "-"}`}</p>
                        </td>

                        <td>
                          <p>{t(tender ? "Yes" : "No")}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.paymentContainer}>
            <div className={styles.tableContainer}>
              <div>
                <h2 className={styles.tableContainer_title}>
                  {t("payment schedule")}
                </h2>

                <p className={styles.tableContainer_subtitle}>
                  {`${t("Next payment of")} ${currencyToFormat(Number(reservation?.next_payment_outstanding ?? "0"))} ${t("on")} ${dayjs(reservation?.next_payment_due ?? null).format(date_format)}`}
                </p>
              </div>

              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("payment date")}</th>
                      <th>{t("payment amount")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(reservation?.payment_schedule ?? []).map((item) => {
                      const { due_date, amount } = item;

                      return (
                        <tr key={nanoid()}>
                          <td>
                            <p>{dayjs(due_date).format(date_format)}</p>
                          </td>

                          <td>
                            <p>{currencyToFormat(Number(amount))}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={styles.tableContainer}>
              <div>
                <h2 className={styles.tableContainer_title}>
                  {t("transactions")}
                </h2>

                <p className={styles.tableContainer_subtitle}>
                  {reservation?.transactions?.length
                    ? `${t("Last transaction of")} ${currencyToFormat(Number(reservation?.transactions?.[reservation?.transactions?.length - 1]?.amount ?? "0"))} ${t("was")} ${dayjs(reservation?.transactions?.[reservation?.transactions?.length - 1]?.made_on ?? null).format(date_format)}`
                    : ""}
                </p>
              </div>

              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("transaction id")}</th>
                      <th>{t("amount")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(reservation?.transactions ?? []).map((item) => {
                      const { card_clearance, amount } = item;

                      return (
                        <tr key={nanoid()}>
                          <td>
                            <p>{card_clearance?.transaction_id ?? ""}</p>
                          </td>

                          <td>
                            <p>{currencyToFormat(Number(amount ?? "0"))}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

// Export the component for use in other parts of the application.
export default Booking;
