import {
  ForwardedRef,
  MutableRefObject,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { createArrayRange } from "../../../../../utils/helpers/createArrayRange";
import { GuestFields } from "../../../../../store/slices/guestsSlice";
import RoomForm from "../RoomForm";
import styles from "./index.module.scss";

interface IRoomFormsProps {
  isIncludesLead: boolean;
  roomNumber: number;
  guestsAmount: number;
}

type ValidateFormsOutput = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
      errors: GuestFields | null;
    }
  >
>;

interface RoomFormsRef {
  validateForms: () => ValidateFormsOutput;
}

const RoomForms = forwardRef<RoomFormsRef, IRoomFormsProps>(function RoomForms(
  { isIncludesLead, roomNumber, guestsAmount }: IRoomFormsProps,
  ref: ForwardedRef<RoomFormsRef>,
) {
  // Create a range based on the number of guests, useful for iterating guest forms.
  const guestsRange = useMemo(
    () => createArrayRange(1, guestsAmount ?? 1),
    [guestsAmount],
  );

  // Initialize roomFormsRefs with useRef
  const roomFormsRefs = useRef<
    Record<
      number,
      MutableRefObject<{
        expand: () => void;
        scrollIntoView: () => void;
        validateForm: () => {
          inputs: GuestFields;
          errors: GuestFields | null;
        };
      } | null>
    >
  >({});

  // Function to validate all RoomForm components and aggregate their results.
  const validateForms = () => {
    const output: ValidateFormsOutput = { [roomNumber]: {} };

    Object.entries(roomFormsRefs.current).forEach(([guestNumber, formRef]) => {
      const validationResult = formRef?.current?.validateForm();

      if (validationResult) {
        output[roomNumber][Number(guestNumber)] = {
          ...formRef.current,
          ...validationResult,
        };
      }
    });

    return output;
  };

  // Expose validateForms method to parent components through ref.
  useImperativeHandle(ref, () => ({ validateForms }), []);

  // Render RoomForm components for each guest.
  return (
    <div className={styles.container}>
      {guestsRange.map((guestNumber) => {
        if (!roomFormsRefs.current[guestNumber]) {
          roomFormsRefs.current[guestNumber] = { current: null };
        }
        const formRef = roomFormsRefs.current[guestNumber];

        return (
          <RoomForm
            key={guestNumber}
            ref={formRef}
            isLead={isIncludesLead && guestNumber === 1}
            guestNumber={guestNumber}
            roomNumber={roomNumber}
          />
        );
      })}
    </div>
  );
});

export default RoomForms;
