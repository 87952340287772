// Importing necessary React hooks for state and effect management.
import { useEffect, useMemo, useState } from "react";

// Importing the AgencyAPI for fetching initial data, action creators for manipulating the agency data within the Redux store.
import { AgencyAPI } from "../../../store/services/AgencyService";
import { createAgency } from "../../../store/actions/agencyActions";
// Constants for routing paths within the application.
import { AGENT_REGISTRATION } from "../../../utils/constants/routes";
// Utility function for validating the agency registration form.
import { validateAgencyRegistration } from "../../../utils/validation";
// Custom hook for accessing typed versions of useDispatch and useSelector from Redux.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";

// Types for handling changes in form inputs and validating them.
import {
  FileChangeHandler,
  IInputs,
  IValidationInputs,
  InputChangeHandler,
} from "./types";

// Reusable UI components for forms.
import CustomLink from "../../shared/CustomLink";
import Input from "../../shared/Input";
import CustomSelect from "../../shared/CustomSelect";
import FilePicker from "../../shared/FilePicker";
import RadioGroup from "../../shared/RadioGroup";
import Button from "../../shared/Button";

// Utility for internationalization, to support multiple languages.
import i18n from "../../../utils/i18n";
import { useTranslation } from "react-i18next";

// Styles specific to the AgencyRegistration component.
import styles from "./index.module.scss";

// Preset values for a radio group component, using internationalization for labels.
const ADDRESS_RADIO_GROUP = {
  0: { value: "different", label: i18n.t("a different address") },
  1: { value: "same", label: i18n.t("no, it's the same") },
};

// The main component for agency registration, utilizing form elements for input.
function AgencyRegistration() {
  const dispatch = useTypedDispatch(); // Hook for dispatching actions to the Redux store.
  const { t } = useTranslation(); // Hook for accessing the translation function.

  // Selector hook to fetch consortia and markets data from the Redux store.
  const { consortia, markets } = useTypedSelector((state) => state.agency);

  // State hook for managing form submission loading state.
  const [isLoading, setIsLoading] = useState(false);
  // State hook for managing form inputs and their error messages.
  const [inputs, setInputs] = useState<IInputs>({
    sales_area: { value: null, errorMessage: "" },
    api_accounts: { value: "", errorMessage: "" },
    name: { value: "", errorMessage: "" },
    commission_level: { value: "", errorMessage: "" },
    credit_limit: { value: "", errorMessage: "" },
    code: { value: "", errorMessage: "" },
    dba: { value: "", errorMessage: "" },
    address: { value: { name: "address", value: "" }, errorMessage: "" },
    telephone: { value: "", errorMessage: "" },
    website: { value: "", errorMessage: "" },
    iata: { value: "", errorMessage: "" },
    clia: { value: "", errorMessage: "" },
    account_type: { value: "", errorMessage: "" },
    consortia: { value: "", errorMessage: "" },
    market: { value: "", errorMessage: "" },
    file: { value: null, errorMessage: "" },
    title: { value: "", errorMessage: "" },
    firstName: { value: "", errorMessage: "" },
    surname: { value: "", errorMessage: "" },
    personalPhoneNumber: { value: "", errorMessage: "" },
    position: { value: "", errorMessage: "" },
    isSameAddress: { value: "same", errorMessage: "" },
    personalAddress: {
      value: { name: "", value: "" },
      errorMessage: "",
    },
    email: { value: "", errorMessage: "" },
    password: { value: "", errorMessage: "" },
    confirmPassword: { value: "", errorMessage: "" },
  });

  // Memoized options for consortia and markets, derived from Redux store data.
  const consortiaOptions = useMemo(
    () => consortia.map((el) => ({ value: el.code, label: el.name })),
    [consortia],
  );

  const marketsOptions = useMemo(
    () => markets.map((el) => ({ value: el.code, label: el.name })),
    [markets],
  );

  // Function to initialize consortia and markets data from the server.
  const init = () => {
    dispatch(AgencyAPI.endpoints.initConsortia.initiate(null));
    dispatch(AgencyAPI.endpoints.initMarkets.initiate(null));
  };

  // Handler for creating an agency based on valid form data.
  const handleCreateAgency = async (validData: IValidationInputs) => {
    setIsLoading(true); // Start loading indicator.

    await dispatch(
      createAgency({
        ...validData,
        address: inputs.address.value,
        file: inputs.file?.value?.[0],
      }),
    );

    setIsLoading(false); // Stop loading indicator.
  };

  const handleAddressChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: {
        errorMessage: "",
        value: { ...inputs.address.value, value },
      },
    }));
  };

  // Handlers for managing form input changes.
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));

    if (valueKey === "isSameAddress" && value === "same") {
      handleAddressChange({
        value: inputs.address.value.value,
        valueKey: "personalAddress",
      });
    }
  };

  const handleFilePick = ({ value, valueKey }: FileChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));
  };

  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (updatedInputs[errorKey as keyof IInputs]) {
        updatedInputs[errorKey as keyof IInputs].errorMessage =
          errors[errorKey as keyof IValidationInputs];
      }
    });

    setInputs(updatedInputs);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const entries = Object.entries(inputs).map(([inputKey, input]) => {
      if (inputKey === "file") {
        return [inputKey, input.value?.[0]?.name];
      }

      if (inputKey === "address") {
        return [inputKey, input.value.value];
      }

      return [inputKey, input.value];
    });

    const data = Object.fromEntries(entries);

    validateAgencyRegistration({
      data,
      onSuccess: async (validData: IValidationInputs) =>
        await handleCreateAgency(validData),
      onError: (errors: IValidationInputs) => handleFormError(errors),
    });
  };

  // Effect hook to initialize form options on component mount.
  useEffect(init, []);

  // Rendering the form with various input components.
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{t("travel agency registration")}</h1>

        <div className={styles.notes}>
          <div className={styles.note}>
            <p className={styles.noteTitle}>{t("please note")}</p>

            <p className={styles.noteDescription}>
              {t(
                "If you are a member of a Host Agency or Franchise and use the same " +
                  "IATA/CLIA number for booking purposes, Please click",
              )}{" "}
              <CustomLink to={AGENT_REGISTRATION} className={styles.link}>
                {t("here to complete your Travel Advisor Registration.")}
              </CustomLink>
            </p>
          </div>
        </div>

        <h2 className={styles.subtitle}>{t("agency details")}</h2>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.groupInputs}>
            <Input
              value={inputs.name.value}
              valueKey="name"
              label={t("agency or company name")}
              placeholder={t("Please tell us your Agency or Company Name")}
              name="name"
              errorMessage={inputs.name.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.commission_level.value}
              valueKey="commission_level"
              label={t("commission level")}
              placeholder={t("Please enter commission level")}
              name="commission_level"
              errorMessage={inputs.commission_level.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.credit_limit.value}
              valueKey="credit_limit"
              label={t("credit limit")}
              placeholder={t("Please enter credit limit")}
              name="credit_limit"
              errorMessage={inputs.credit_limit.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.code.value}
              valueKey="code"
              label={t("agency code")}
              placeholder={t("Please enter Agency code")}
              name="code"
              errorMessage={inputs.code.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.dba.value}
              valueKey="dba"
              label={t("dba (in applicable)")}
              placeholder={t("DBA")}
              name="dba"
              onChange={handleInputChange}
            />
          </div>

          <Input
            value={inputs.address.value.value}
            valueKey="address"
            label={t("agency or company address")}
            placeholder={t("Start typing your address")}
            name="address"
            errorMessage={inputs.address.errorMessage}
            onChange={handleAddressChange}
            isRequired
          />

          <div className={styles.groupInputs}>
            <Input
              value={inputs.telephone.value}
              valueKey="telephone"
              label={t("agency or company phone number")}
              placeholder={t("Your phone number")}
              name="telephone"
              errorMessage={inputs.telephone.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.website.value}
              valueKey="website"
              label={t("website")}
              placeholder={t("Add your website URL")}
              name="website"
              errorMessage={inputs.website.errorMessage}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.group}>
            <div className={styles.groupInputs}>
              <Input
                value={inputs.iata.value}
                valueKey="iata"
                label={t("iata number")}
                placeholder={t("Your IATA number")}
                name="iata"
                errorMessage={inputs.iata.errorMessage}
                onChange={handleInputChange}
                isRequired
              />

              <Input
                value={inputs.clia.value}
                valueKey="clia"
                label={t("clia number")}
                placeholder={t("Your CLIA number")}
                name="clia"
                errorMessage={inputs.clia.errorMessage}
                onChange={handleInputChange}
                isRequired
              />
            </div>
          </div>

          <div className={styles.groupInputs}>
            <CustomSelect
              items={[{ value: "creditcardonly", label: "Credit Card" }]}
              value={inputs.account_type.value}
              valueKey="account_type"
              label={t("type of organization")}
              placeholder={t("Please select one")}
              errorMessage={inputs.account_type.errorMessage}
              onChange={handleInputChange}
              isRequired
            />

            <CustomSelect
              items={consortiaOptions}
              value={inputs.consortia.value}
              valueKey="consortia"
              label={t("consortia")}
              placeholder={t("Please select one")}
              errorMessage={inputs.consortia.errorMessage}
              onChange={handleInputChange}
              isRequired
            />

            <CustomSelect
              items={marketsOptions}
              value={inputs.market.value}
              valueKey="market"
              label={t("market")}
              placeholder={t("Please select one")}
              errorMessage={inputs.market.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("w-9 form")}</h2>

              <p className={styles.text}>
                {t("Please attach your W-9 Form (PDF,DOC, JPEG)")}
              </p>
            </div>

            <FilePicker
              valueKey="file"
              errorMessage={inputs.file.errorMessage}
              onChange={handleFilePick}
            />
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("your details")}</h2>

              <p className={styles.text}>
                {t(
                  "You will be the primary contact for this Agency or Company",
                )}
              </p>
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.title.value}
                valueKey="title"
                label={t("title")}
                placeholder={t("Please tell us your title")}
                name="title"
                errorMessage={inputs.title.errorMessage}
                onChange={handleInputChange}
                isRequired
              />

              <Input
                value={inputs.firstName.value}
                valueKey="firstName"
                label={t("your first name")}
                placeholder={t("Please tell us your first name")}
                name="firstName"
                errorMessage={inputs.firstName.errorMessage}
                onChange={handleInputChange}
                isRequired
              />

              <Input
                value={inputs.surname.value}
                valueKey="surname"
                label={t("your surname")}
                placeholder={t("Please tell us your second name")}
                name="surname"
                errorMessage={inputs.surname.errorMessage}
                onChange={handleInputChange}
                isRequired
              />
            </div>
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.personalPhoneNumber.value}
              valueKey="personalPhoneNumber"
              label={t("your phone number")}
              placeholder={t("Your phone number")}
              name="personalPhoneNumber"
              errorMessage={inputs.personalPhoneNumber.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <CustomSelect
              items={[{ value: "agent", label: "Agent" }]}
              value={inputs.position.value}
              valueKey="position"
              label={t("position")}
              placeholder={t("Please select one")}
              errorMessage={inputs.position.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("agent address")}</h2>

              <p className={styles.text}>
                {t(
                  "Is your address different to the Agency or Company information " +
                    "provided above?",
                )}
              </p>
            </div>

            <RadioGroup
              value={inputs.isSameAddress.value}
              valueKey="isSameAddress"
              options={ADDRESS_RADIO_GROUP}
              onChange={handleInputChange}
            />
          </div>

          {inputs.isSameAddress.value === "different" && (
            <Input
              value={inputs.personalAddress.value.value}
              valueKey="personalAddress"
              label={t("your address")}
              placeholder={t("Start typing your address")}
              name="personalAddress"
              errorMessage={inputs.personalAddress.errorMessage}
              onChange={handleAddressChange}
              isRequired
            />
          )}

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("account details")}</h2>

              <p className={styles.text}>
                {t("Use these to access Atlas Advisor Central")}
              </p>
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.email.value}
                valueKey="email"
                label={t("your email address")}
                placeholder={t("Your email address")}
                name="email"
                errorMessage={inputs.email.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
              />
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.password.value}
                valueKey="password"
                label={t("password")}
                placeholder={t("Enter a password")}
                name="password"
                errorMessage={inputs.password.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
                secured
              />

              <Input
                value={inputs.confirmPassword.value}
                valueKey="confirmPassword"
                label={t("confirm password")}
                placeholder={t("Enter a password confirmation")}
                name="confirmPassword"
                errorMessage={inputs.confirmPassword.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
                secured
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              label={t("complete registration")}
              type="submit"
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

// Export the AgencyRegistration component for use elsewhere in the application.
export default AgencyRegistration;
