import CustomLink from "../CustomLink"; // Import CustomLink component
import SvgIcon from "../SvgIcon"; // Import SvgIcon component

import styles from "./index.module.scss"; // Import styles specific to the Logo component

// Interface defining props for the Logo component
interface ILogoProps {
  withText?: boolean; // Optional: Indicates whether the logo includes text
  pathname?: string; // Optional: Pathname for the CustomLink component
}

// Interface defining props for the LogoContainer component
interface ILogoContainerProps {
  pathname?: string; // Optional: Pathname for the CustomLink component
  className: string; // Required: CSS class name for styling the container
}

/**
 * Represents a container component for the logo.
 *
 * @param {string} pathname - Pathname for the CustomLink component.
 * @param {string} className - CSS class name for styling the container.
 * @param {React.ReactNode} children - Nested components or elements.
 * @returns {JSX.Element} LogoContainer component.
 */
function LogoContainer({
  pathname,
  className,
  children,
}: React.PropsWithChildren<ILogoContainerProps>) {
  if (pathname) {
    return (
      <CustomLink to={pathname} className={className}>
        {children}
      </CustomLink>
    );
  }

  return <div className={className}>{children}</div>;
}

/**
 * Represents a logo component.
 *
 * @param {boolean} [withText=false] - Indicates whether the logo should include text.
 * @param {string} pathname - Pathname for the LogoContainer component.
 * @returns {JSX.Element} Logo component.
 */
function Logo({ withText, pathname }: ILogoProps) {
  return (
    <LogoContainer className={styles.container} pathname={pathname}>
      {/* Render the logo icon */}
      <SvgIcon type="logo" className={styles.logo} />

      {/* Render text if withText is true */}
      {withText && (
        <div className={styles.text}>
          {/* Render the title */}
          <p className={styles.title}>atlas</p>
          {/* Render the slogan */}
          <p className={styles.slogan}>ocean voyages</p>
        </div>
      )}
    </LogoContainer>
  );
}

// Default props for the Logo component
Logo.defaultProps = {
  withText: false, // Logo does not include text by default
};

// Export the Logo component as default
export default Logo;
