import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing createSlice and PayloadAction from Redux Toolkit

// Interface defining the structure of environment variables
interface Variables {
  api_url: string;
  pos_url: string;
  search_url: string;
  payment_url: string;
  api_username: string;
  api_password: string;
  payment_api_path: string;
  date_format: string;
  app_language: string;
  api_language: string;
  breadcrumbs_text: string;
  search_item_image_source: "ship" | "cruise";
  authorize_api_login_id: string;
  authorize_client_key: string;
  privacy_policy: string;
  payment_checkbox_1: string;
  payment_checkbox_2: string;
  payment_checkbox_3: string;
  payment_checkbox_4: string;
  payment_checkbox_5: string;
  subtract_gft: boolean;
  strip_no_stop_itinerary_items: boolean;
  show_pricing_breakdown: boolean;
  check_email_uniqueness: boolean;
  authorize_is_test: boolean;
  authorize_is_mine: boolean;
  primary_color: string;
  secondary_color: string;
  text_primary_color: string;
  text_secondary_color: string;
  input_border_color: string;
  input_background_color: string;
  input_placeholder_color: string;
  input_text_color: string;
  input_focus_border_color: string;
  error_color: string;
  menu_background_color: string;
  menu_text_color: string;
  element_background_primary: string;
  element_background_secondary: string;
  element_background_dark: string;
  background_color: string;
  link_color: string;
  section_background_color: string;
  shadow_color: string;
  subtext_color: string;
  delimiter_line_color: string;
  svg_icons_primary: string;
  svg_icons_secondary: string;
  search_button_background: string;
  pagination_type: string;
  pagination_count: number;
}

// Interface representing the state of the environment variables
export interface EnvironmentState extends Variables {
  isInitialized: boolean;
}

// Initial state of the environment variables
const initialState: EnvironmentState = {
  api_url: "",
  pos_url: "",
  search_url: "",
  payment_url: "",
  api_username: "",
  api_password: "",
  payment_api_path: "",
  date_format: "",
  app_language: "",
  api_language: "",
  breadcrumbs_text: "",
  search_item_image_source: "ship",
  authorize_api_login_id: "",
  authorize_client_key: "",
  privacy_policy: "",
  payment_checkbox_1: "",
  payment_checkbox_2: "",
  payment_checkbox_3: "",
  payment_checkbox_4: "",
  payment_checkbox_5: "",
  subtract_gft: true,
  strip_no_stop_itinerary_items: true,
  show_pricing_breakdown: true,
  check_email_uniqueness: true,
  authorize_is_test: true,
  authorize_is_mine: true,
  primary_color: "",
  secondary_color: "",
  text_primary_color: "",
  text_secondary_color: "",
  input_border_color: "",
  input_background_color: "",
  input_placeholder_color: "",
  input_text_color: "",
  input_focus_border_color: "",
  error_color: "",
  menu_background_color: "",
  menu_text_color: "",
  element_background_primary: "",
  element_background_secondary: "",
  element_background_dark: "",
  background_color: "",
  link_color: "",
  section_background_color: "",
  shadow_color: "",
  subtext_color: "",
  delimiter_line_color: "",
  svg_icons_primary: "",
  svg_icons_secondary: "",
  search_button_background: "",
  pagination_type: "default",
  pagination_count: 10,
  isInitialized: false,
};

// Creating a slice for environment variables
const environmentSlice = createSlice({
  name: "environment", // Setting the name of the slice
  initialState, // Setting the initial state
  reducers: {
    // Defining reducer functions
    setEnvVariables(state: EnvironmentState, action: PayloadAction<Variables>) {
      state.api_url = action.payload.api_url;
      state.pos_url = action.payload.pos_url;
      state.search_url = action.payload.search_url;
      state.payment_url = action.payload.payment_url;
      state.payment_api_path = action.payload.payment_api_path;
      state.date_format = action.payload.date_format;
      state.app_language = action.payload.api_language;
      state.api_language = action.payload.api_language;
      state.breadcrumbs_text = action.payload.breadcrumbs_text;
      state.search_item_image_source =
        action.payload.search_item_image_source || "ship";
      state.authorize_api_login_id = action.payload.authorize_api_login_id;
      state.authorize_client_key = action.payload.authorize_client_key;
      state.privacy_policy = action.payload.privacy_policy;
      state.payment_checkbox_1 = action.payload.payment_checkbox_1;
      state.payment_checkbox_2 = action.payload.payment_checkbox_2;
      state.payment_checkbox_3 = action.payload.payment_checkbox_3;
      state.payment_checkbox_4 = action.payload.payment_checkbox_4;
      state.payment_checkbox_5 = action.payload.payment_checkbox_5;
      state.subtract_gft = action.payload.subtract_gft;
      state.strip_no_stop_itinerary_items =
        action.payload.strip_no_stop_itinerary_items;
      state.show_pricing_breakdown = action.payload.show_pricing_breakdown;
      state.check_email_uniqueness = action.payload.check_email_uniqueness;
      state.authorize_is_test = action.payload.authorize_is_test;
      state.authorize_is_mine = action.payload.authorize_is_mine;
      state.primary_color = action.payload.primary_color;
      state.secondary_color = action.payload.secondary_color;
      state.text_primary_color = action.payload.text_primary_color;
      state.text_secondary_color = action.payload.text_secondary_color;
      state.input_border_color = action.payload.input_border_color;
      state.input_background_color = action.payload.input_background_color;
      state.input_placeholder_color = action.payload.input_placeholder_color;
      state.input_text_color = action.payload.input_text_color;
      state.input_focus_border_color = action.payload.input_focus_border_color;
      state.error_color = action.payload.error_color;
      state.menu_background_color = action.payload.menu_background_color;
      state.menu_text_color = action.payload.menu_text_color;
      state.element_background_primary =
        action.payload.element_background_primary;
      state.element_background_secondary =
        action.payload.element_background_secondary;
      state.element_background_dark = action.payload.element_background_dark;
      state.background_color = action.payload.background_color;
      state.link_color = action.payload.link_color;
      state.section_background_color = action.payload.section_background_color;
      state.shadow_color = action.payload.shadow_color;
      state.subtext_color = action.payload.subtext_color;
      state.delimiter_line_color = action.payload.delimiter_line_color;
      state.svg_icons_primary = action.payload.svg_icons_primary;
      state.search_button_background = action.payload.search_button_background;
      state.pagination_type = action.payload.pagination_type;
      state.pagination_count = isNaN(Number(action.payload.pagination_count))
        ? 10
        : Number(action.payload.pagination_count);
      state.isInitialized = true;
    },
  },
});

// Exporting action creators
export const { setEnvVariables } = environmentSlice.actions;

// Exporting reducer function
export default environmentSlice.reducer;
