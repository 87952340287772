// Import useState for local state management, useTranslation for internationalization.
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Custom hook for fetching and handling search-related operations.
import useSearch from "../../../hooks/useSearch";
// Hook for dispatching Redux actions.
import { useTypedDispatch } from "../../../store/store";
// Redux action for creating a new agent record.
import { createAgent } from "../../../store/actions/agencyActions";
// Constants for navigation paths.
import { AGENCY_REGISTRATION } from "../../../utils/constants/routes";
// Utility function for validating agent registration form inputs.
import { validateAgentRegistration } from "../../../utils/validation";
// Types for form input state and handlers.
import { IInputs, IValidationInputs, InputChangeHandler } from "./types";

// Reusable UI components.
import CustomLink from "../../shared/CustomLink";
import Input from "../../shared/Input";
import SearchInput from "../../shared/SearchInput";
import CustomSelect from "../../shared/CustomSelect";
import Button from "../../shared/Button";

// Styles specific to this component.
import styles from "./index.module.scss";

// The main functional component for agent registration.
function AgentRegistration() {
  // Setup dispatch and internationalization hooks.
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  // Destructuring methods from the useSearch hook for agency search.
  const { agencies, searchHandlers, resetHandlers } = useSearch();

  // Local state for loading indicator and form inputs.
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState<IInputs>({
    sales_area: { value: null, errorMessage: "" },
    name: { value: "", errorMessage: "" },
    firstName: { value: "", errorMessage: "" },
    surname: { value: "", errorMessage: "" },
    agency: { value: "", id: "", errorMessage: "" },
    address: { value: { name: "address", value: "" }, errorMessage: "" },
    telephone: { value: "", errorMessage: "" },
    code: { value: "", errorMessage: "" },
    position: { value: "", errorMessage: "" },
    iata: { value: "", errorMessage: "" },
    clia: { value: "", errorMessage: "" },
    email: { value: "", errorMessage: "" },
    password: { value: "", errorMessage: "" },
    confirmPassword: { value: "", errorMessage: "" },
    is_web: { value: true, errorMessage: "" },
    is_default: { value: true, errorMessage: "" },
    skip_external: { value: true, errorMessage: "" },
  });

  // Handler for form submission, which includes validation and dispatching createAgent action.
  const handleCreateAgent = async (validData: IValidationInputs) => {
    setIsLoading(true); // Begin loading.

    await dispatch(
      createAgent({
        ...validData,
        address: inputs.address.value,
      }),
    );

    setIsLoading(false); // End loading.
  };

  // Handlers for various form input changes.
  const handleAddressChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: {
        errorMessage: "",
        value: { ...inputs.address.value, value },
      },
    }));
  };

  // Function to initiate agency search or reset based on input value.
  const searchAgency = async (value: string) => {
    if (value) {
      await searchHandlers.agencySearch(value);
    } else {
      resetHandlers.agenciesReset();
    }
  };

  // General input change handler to update local state.
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));

    if (valueKey === "agency") {
      searchAgency(value);
    }
  };

  // Handler for choosing an agency from search results.
  const handleAgencyChose = ({ value }: { value: Record<string, string> }) => {
    setInputs((prev) => ({
      ...prev,
      agency: { errorMessage: "", value: value.name, id: value.id },
    }));
  };

  // Handler for processing form errors after validation.
  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (errorKey in updatedInputs) {
        const value = String(errors[errorKey as keyof IValidationInputs]);

        updatedInputs[errorKey as keyof IInputs].errorMessage = value;
      }
    });

    setInputs(updatedInputs);
  };

  // Handler for form submission, which performs validation and potentially creates an agent.
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const entries = Object.entries(inputs).map(([inputKey, input]) => {
      if (inputKey === "address") {
        return [inputKey, input.value.value];
      }

      return [inputKey, input.id ?? input.value];
    });

    const data = Object.fromEntries(entries);

    validateAgentRegistration({
      data,
      onSuccess: async (validData: IValidationInputs) =>
        await handleCreateAgent(validData),
      onError: (errors: IValidationInputs) => handleFormError(errors),
    });
  };

  // Render the agent registration form.
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{t("travel advisor registration")}</h1>

        <div className={styles.notes}>
          <div className={styles.note}>
            <p className={styles.noteTitle}>{t("please note")}</p>

            <p className={styles.noteDescription}>
              {t(
                "Your AGENCY must be registered BEFORE completing the Travel " +
                  "Advisor Registration. If your AGENCY is not registered, please " +
                  "contact your business owner and register through the Travel Agency " +
                  "Registration ",
              )}{" "}
              <CustomLink to={AGENCY_REGISTRATION} className={styles.link}>
                here.
              </CustomLink>
            </p>
          </div>

          <div className={styles.note}>
            <p className={styles.noteTitle}>instructions</p>

            <p className={styles.noteDescription}>
              {t(
                "If you are a member of a Host Agency or Franchise and use their " +
                  "IATA/CLIA number for booking purposes, please enter their " +
                  "information below. If you are not, please enter your agency " +
                  "information in both sections.",
              )}
            </p>
          </div>
        </div>

        <h2 className={styles.subtitle}>{t("your details")}</h2>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.groupInputs}>
            <Input
              value={inputs.name.value}
              valueKey="name"
              label={t("title")}
              placeholder={t("Please tell us your title")}
              name="name"
              errorMessage={inputs.name.errorMessage}
              onChange={handleInputChange}
              isRequired
            />

            <Input
              value={inputs.firstName.value}
              valueKey="firstName"
              label={t("your first name")}
              placeholder={t("Please tell us your first name")}
              name="firstName"
              errorMessage={inputs.firstName.errorMessage}
              onChange={handleInputChange}
              isRequired
            />

            <Input
              value={inputs.surname.value}
              valueKey="surname"
              label={t("your surname")}
              placeholder={t("Please tell us your second name")}
              name="surname"
              errorMessage={inputs.surname.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <Input
            value={inputs.address.value.value}
            valueKey="address"
            label={t("agency or company address")}
            placeholder={t("Start typing your address")}
            name="address"
            errorMessage={inputs.address.errorMessage}
            onChange={handleAddressChange}
            isRequired
          />

          <div className={styles.groupInputs}>
            <Input
              value={inputs.telephone.value}
              valueKey="telephone"
              label={t("your phone number")}
              placeholder={t("Your phone number")}
              name="telephone"
              errorMessage={inputs.telephone.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.code.value}
              valueKey="code"
              label={t("code")}
              placeholder={t("Code")}
              name="code"
              errorMessage={inputs.code.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("agency details")}</h2>

              <p className={styles.text}>
                {t(
                  "If you are a member of a Host Agency or Franchise and use their " +
                    "IATA/CLIA number for booking purposes, please enter their " +
                    "information below. If you are not, please enter your agency " +
                    "information.",
                )}
              </p>
            </div>

            <div className={styles.groupInputs}>
              <SearchInput
                value={inputs.agency.value}
                valueKey="agency"
                displayKey="name"
                results={agencies.results}
                label={t("agency or company name")}
                placeholder={t("Type for ships, countries, companies")}
                errorMessage={inputs.agency.errorMessage}
                isLoading={agencies.isLoading}
                onChange={handleInputChange}
                onChosenChange={handleAgencyChose}
                isRequired
              />
            </div>

            <div className={styles.groupInputs}>
              <CustomSelect
                items={[{ value: "agent", label: "Agent" }]}
                value={inputs.position.value}
                valueKey="position"
                label={t("position")}
                placeholder={t("Please select one")}
                errorMessage={inputs.position.errorMessage}
                onChange={handleInputChange}
                isRequired
              />
            </div>
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.iata.value}
              valueKey="iata"
              label={t("iata number")}
              placeholder={t("Your IATA number")}
              name="iata"
              errorMessage={inputs.iata.errorMessage}
              onChange={handleInputChange}
              isRequired
            />

            <Input
              value={inputs.clia.value}
              valueKey="clia"
              label={t("clia number")}
              placeholder={t("Your CLIA number")}
              name="clia"
              errorMessage={inputs.clia.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("account details")}</h2>

              <p className={styles.text}>
                {t("Use these to access Atlas Advisor Central")}
              </p>
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.email.value}
                valueKey="email"
                label={t("your email address")}
                placeholder={t("Your email address")}
                name="email"
                errorMessage={inputs.email.errorMessage}
                onChange={handleInputChange}
                isRequired
              />
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.password.value}
                valueKey="password"
                label={t("password")}
                placeholder={t("Enter a password")}
                name="password"
                errorMessage={inputs.password.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
                secured
              />

              <Input
                value={inputs.confirmPassword.value}
                valueKey="confirmPassword"
                label={t("confirm password")}
                placeholder={t("Enter a password confirmation")}
                name="confirmPassword"
                errorMessage={inputs.confirmPassword.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
                secured
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              label={t("complete registration")}
              type="submit"
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

// Export the component for use in the application.
export default AgentRegistration;
