// Import React hooks.
import { useMemo } from "react";
import { nanoid } from "@reduxjs/toolkit"; // Import utility to generate unique IDs.
import classNames from "classnames"; // Utility for conditional class names.
import { useTranslation } from "react-i18next"; // Hook for internationalization.

// Utility function for formatting currency.
import { currencyToFormat } from "../../../../../utils/helpers/currency";
// Hooks for accessing Redux store state.
import { useTypedSelector } from "../../../../../store/store";

// Import shared components.
import CustomCarousel from "../../../../shared/Carousel";
import Collapsible from "../../../../shared/Collapsible";

// Import component-specific styles.
import styles from "./index.module.scss";

// Define the ItineraryModal functional component.
function ItineraryModal() {
  const { t } = useTranslation(); // Hook to access translation functionality.

  // Retrieve cruise, rooms, and environment settings from Redux store.
  const { cruise } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { strip_no_stop_itinerary_items } = useTypedSelector(
    (state) => state.environment,
  );

  // Compute the minimum price from cruise market prices.
  const price = useMemo(() => {
    if (!cruise) {
      return 0;
    }

    const prices = Object.values(cruise.markets)
      .map((market) => parseFloat(market.price))
      .filter((price) => !isNaN(price) && price !== 0);

    return Math.min(...prices);
  }, []);

  // Function to validate time values based on the environment setting.
  const validateTime = (value: string | null) => {
    if (value === "00:00" && strip_no_stop_itinerary_items) {
      return false;
    }

    return Boolean(value);
  };

  // Render the modal content.
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* Carousel and map display */}
        <div className={styles.imagesContainer}>
          <div
            className={classNames(styles.images, {
              [styles.images_twoColumns]: cruise?.cruise.map,
            })}>
            <CustomCarousel items={cruise?.ship.images ?? []} />

            {cruise?.cruise.map && (
              <img src={cruise?.cruise.map} className={styles.map} />
            )}
          </div>

          <p className={styles.name}>{cruise?.cruise.name ?? ""}</p>
        </div>

        {/* Cruise details */}
        <table className={styles.details}>
          <tbody>
            <tr>
              <td>{t("SHIP NAME")}</td>
              <td>{cruise?.ship.name ?? ""}</td>
            </tr>

            <tr>
              <td>{t("DEPARTS")}</td>
              <td>{cruise?.embark}</td>
            </tr>
          </tbody>
        </table>

        {/* Display calculated price */}
        <div className={styles.price}>
          <span className={styles.price_from}>{t("From")}</span>

          <span className={styles.price_value}>
            {currencyToFormat(
              price,
              rooms?.[1]?.pricing?.payment_schedule?.[0]?.currency ?? "USD",
            )}
          </span>
        </div>

        {/* Itinerary list */}
        <div className={styles.itinerary}>
          {cruise?.cruise.itinerary.map((it) => {
            const { port, arrive_time, depart_time, description } = it;

            if (!validateTime(arrive_time) && !validateTime(depart_time))
              return <></>;

            return (
              <Collapsible
                key={nanoid()}
                className={styles.svg_ico}
                renderHeader={() => (
                  <span className={styles.itineraryTitle}>{port}</span>
                )}>
                <table className={styles.details}>
                  <tbody>
                    {validateTime(arrive_time) && (
                      <tr>
                        <td>{t("ARRIVES")}</td>
                        <td>{arrive_time}</td>
                      </tr>
                    )}

                    {validateTime(depart_time) && (
                      <tr>
                        <td>{t("DEPARTS")}</td>
                        <td>{depart_time}</td>
                      </tr>
                    )}

                    {description && (
                      <tr className={styles.description}>
                        <td>{t("DESCRIPTION")}</td>
                        <td dangerouslySetInnerHTML={{ __html: description }} />
                      </tr>
                    )}
                  </tbody>
                </table>
              </Collapsible>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// Export the component for use elsewhere.
export default ItineraryModal;
